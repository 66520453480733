import { Discount } from '../../types/DiscountTypes'
import { fixMathRounding, getActivitiesWithDiscountId, getPriceFromCalculation } from './CalculateDiscountHelpers'
import {
  CalculateDiscountLogic,
  Calculation,
  CalculationDiscount,
  CalculationUserInput
} from './CalculateDiscountTypes'

export class CombinedDiscountLogic implements CalculateDiscountLogic {
  type = 'combined'
  discountDefinition: Discount

  constructor(discount: Discount) {
    this.discountDefinition = discount
  }

  chooseEligible(previousCalculations: Calculation[], userInput: CalculationUserInput) {
    // group activities with the same group, output: { groupName: [activityId1, activityId2, ...], groupName1: [id1], ... }
    const activityIds: string[] = []
    previousCalculations.reduce(
      (acc, calculation) => {
        const group = calculation.activity.group
        if (!group) return acc
        acc[group] = acc[group] || []
        acc[group].push(calculation.activity.id)
        // main logic here: collect activities that have the same group and the group has at least 2 activities; this will have duplicates, we will remove them later
        if (acc[group].length > 1) acc[group].forEach((activityId) => activityIds.push(activityId))
        return acc
      },
      {} as Record<string, string[]>
    )
    // get unique values only from activityIds
    const combinedActivityIds = activityIds.filter((activityId, index) => activityIds.indexOf(activityId) === index)

    // check get activity IDs which have this discount allowed
    const eligibleActivities = getActivitiesWithDiscountId(previousCalculations, this.discountDefinition.id)

    // return only the activities that are in the combinedActivityIds
    return eligibleActivities.filter((activityId) => combinedActivityIds.includes(activityId))
  }

  calculate(previousCalculations: Calculation[], userInput: CalculationUserInput) {
    const eligibleActivities = this.chooseEligible(previousCalculations, userInput)

    previousCalculations.map((calculation) => {
      if (!eligibleActivities.includes(calculation.activity.id)) return
      const activityPrice = getPriceFromCalculation(calculation)
      const discountAmount =
        this.discountDefinition.calculationType === 'amount'
          ? this.discountDefinition.value // amount discount
          : fixMathRounding(activityPrice * (this.discountDefinition.value / 100)) // percentage discount
      const combinedDiscount: CalculationDiscount = {
        type: this.type,
        name: this.discountDefinition.name,
        id: this.discountDefinition.id,
        input: activityPrice,
        discount: discountAmount,
        output: fixMathRounding(activityPrice - discountAmount),
        discountCalculationType: this.discountDefinition.calculationType,
        discountValue: this.discountDefinition.value
      }
      calculation.discounts = calculation.discounts || []
      calculation.discounts.push(combinedDiscount)
      calculation.finalPrice = combinedDiscount.output
    })

    return previousCalculations
  }
}
