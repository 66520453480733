import { Elements } from '@stripe/react-stripe-js'

import getStripe from '../libs/getStripe'
import StripeCheckoutForm from '../components/StripeCheckoutForm'

import * as stripeJs from '@stripe/stripe-js'
import Box from '../components/Box'
import { useEffect, useState } from 'react'
import { createStripePaymentIntent } from '../libs/CloudFunctionsApiHandler'
import { CreateStripePaymentIntentWebRequest, StripeConfig } from '../../../pure-js/types/StripeTypes'
import useAppState from '../hooks/useAppState'
import { useParams } from 'react-router-dom'
import { getClient, getStripeConfig } from '../libs/DBApiHandler'
import { Client } from '../../../pure-js/types/GrooverTypes'

const PayPage = () => {
  const { enrollmentId, clientId } = useParams<{ enrollmentId: string; clientId: string }>()

  const [client, setClient] = useState<Client>()
  const [isError, setIsError] = useState(false)
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('')
  const [stripeConfig, setStripeConfig] = useState<StripeConfig>()
  const [stripeClientSecret, setStripeClientSecret] = useState<string | null>(null)
  const [stripeOptions, setStripeOptions] = useState<stripeJs.StripeElementsOptions>()
  const { state } = useAppState()

  useEffect(() => {
    if (!enrollmentId) return

    const fetchClient = async () => {
      if (clientId) {
        const clientData = await getClient(clientId)
        if (clientData) {
          setClient(clientData)
        }
      }
    }

    const setupStripe = async () => {
      if (!client) return

      // Stripe config
      const config = await getStripeConfig(client)
      setStripeConfig(config)

      // Stripe intent
      const request: CreateStripePaymentIntentWebRequest = {
        enrollmentId: enrollmentId
      }
      const result = await createStripePaymentIntent(request, state)
      if ('isError' in result) {
        setIsError(true)
        console.error('Error creating Stripe payment intent:', result)
      } else {
        setStripeClientSecret(result.clientSecret)
      }
    }

    fetchClient()
    setupStripe()
  }, [])

  useEffect(() => {
    if (!stripeClientSecret) return
    setStripeOptions({ clientSecret: stripeClientSecret })
  }, [stripeClientSecret])

  return (
    <Box width="650px" fullHeight>
      {!enrollmentId && <h1>No enrollment ID</h1>}
      {!isError && !stripeClientSecret && <h1>Initializing...</h1>}
      {isError && <h1>Error initializing payment</h1>}
      {enrollmentId && stripeClientSecret && (
        <>
          <h1>
            Pay {amount}
            {currency}
          </h1>
          {stripeConfig && stripeOptions && (
            <Elements stripe={getStripe(stripeConfig)} options={stripeOptions}>
              <StripeCheckoutForm />
            </Elements>
          )}
        </>
      )}
    </Box>
  )
}

export default PayPage
