import styled from '@emotion/styled'
import { EIGHT_PX, SIXTEEN_PX } from '../enums/Spacings'
import { Grey } from '../../../pure-js/libs/Colors'

export const LinkStyles = `
  cursor: pointer;
  :hover {
    text-decoration: underline;
  };
`

export const Flexbox = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
`

export const ClosedContainer = styled(Flexbox)`
  width: 96%;
  height: 100%;
  cursor: pointer;
  max-height: 70px;
  border-radius: 12px;
  padding: ${SIXTEEN_PX};
  background-color: #fcfcfc;
  border: 1px solid #f5eded;

  @media (max-width: 768px) {
    width: 91%;
  }
`

export const OpenContainer = styled(Flexbox)`
  width: 96%;
  height: 100%;
  gap: ${EIGHT_PX};
  border-radius: 12px;
  padding: ${SIXTEEN_PX};
  border: 1px solid #f5eded;
  background-color: '#f9f9f9';
  box-shadow: 0px 0px 8px 0px #2422bb;

  @media (max-width: 768px) {
    width: 91%;
  }
`

export const ContinueButton = styled.button<{ isDisabled?: boolean }>`
  gap: 16px;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: ${(props) => (props.isDisabled ? '#ccc' : 'rgba(59, 89, 236, 1)')};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`

export const AbortButton = styled.button<{ isDisabled?: boolean }>`
  gap: 16px;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: ${(props) => (props.isDisabled ? '#ccc' : 'rgb(236, 59, 62)')};
`

export const GreyLine = styled(Flexbox)`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${Grey};
`
