import { Texts } from '../../../pure-js/libs/Texts'
import { EIGHT_PX, FOUR_PX } from '../enums/Spacings'
import { State } from '../hooks/useAppState'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText from './FigmaText'
import iconCompleted from '../../public/iconCompleted.svg'
import { memo, useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Activity, PaymentMethod } from '../../../pure-js/types/GrooverTypes'
import ActivityDetails from './ActivityDetails'
import { useLanguage } from '../hooks/useDefaultLanguage'
import { useClient } from '../hooks/useClient'

type Props = {
  state: State
  activity: Activity
}

const CourseRegistrationStepFiveOpenedContent = ({ state, activity }: Props) => {
  const language = useLanguage()
  const client = useClient(activity)
  const [expandedActivityId, setExpandedActivityId] = useState<string | null>(null)

  const googleMapsUrl = useMemo(
    () => client?.locations?.find((location) => location?.id === activity?.locationId)?.googleUrl,
    [client, activity]
  )

  const onOpenGoogleMaps = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      window.open(googleMapsUrl)
    },
    [googleMapsUrl]
  )
  return (
    <>
      <Box direction="row" gap="15px" bottom spacing={FOUR_PX}>
        {state.isRegistrationCompleted && <img src={iconCompleted} alt="Completed icon" width={24} height={24} />}
        <FigmaText
          textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
          text={getFigmaText(Texts.registrationHeadersAndStatusHeaderFinalSummary)}
        />
      </Box>

      {!state.isPaid && state.paymentMethod === PaymentMethod.BankTransfer && (
        <InfoContainer fullWidth align="center" justify="center" gap={EIGHT_PX}>
          <FigmaText textKey={Texts.registrationStep5PaymentWaitingBankInfo} />
          <FigmaText
            textKey={Texts.registrationStep5PaymentPayInfoBankAccount}
            text={
              language === 'sv'
                ? `Betala ${state?.finalPrice} till bankgironummer: ${client?.bankGiro}. Ange ditt kompletta namn som referens.`
                : `Pay ${state?.finalPrice} to bankgiro: ${client?.bankGiro}. Use your complete name as reference.`
            }
          />
        </InfoContainer>
      )}

      {state.isPaid && (
        <>
          <FigmaText textKey={Texts.registrationStep6SummarySummaryWelcomeToCourses} />
          {state.selectedActivities.map((activity) => (
            <ActivityDetails
              key={activity.id}
              activity={activity}
              expanded={expandedActivityId === activity.id}
              onExpand={() => setExpandedActivityId(activity.id === expandedActivityId ? null : activity.id)}
              onOpenGoogleMaps={onOpenGoogleMaps}
            />
          ))}
        </>
      )}
    </>
  )
}

const InfoContainer = styled(Box)`
  padding: 10px;
  background-color: rgb(243, 250, 194);
`

export default memo(CourseRegistrationStepFiveOpenedContent)
