import { useEffect, useState } from 'react'
import { Client, Activity } from '../../../pure-js/types/GrooverTypes'
import { getClient } from '../libs/DBApiHandler'

export const useClient = (activity: Activity) => {
  const [client, setClient] = useState<Client | undefined>()

  useEffect(() => {
    const fetchClient = async () => {
      if (activity.clientId) {
        const clientData = await getClient(activity.clientId)
        if (clientData) setClient(clientData)
      }
    }
    fetchClient()
  }, [activity])

  return client
}
