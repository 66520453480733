import { Discount } from '../../types/DiscountTypes'
import { fixMathRounding, getActivitiesWithDiscountId, getPriceFromCalculation } from './CalculateDiscountHelpers'
import {
  CalculateDiscountLogic,
  Calculation,
  CalculationDiscount,
  CalculationUserInput
} from './CalculateDiscountTypes'

export class MemberDiscountLogic implements CalculateDiscountLogic {
  type = 'member'
  discountDefinition: Discount

  constructor(discount: Discount) {
    this.discountDefinition = discount
  }

  chooseEligible(previousCalculations: Calculation[], userInput: CalculationUserInput) {
    if (!userInput.userActivePeriods) return []

    return previousCalculations
      // get only these activities that are in user's active periods
      .filter((calculation) => userInput.userActivePeriods?.includes(calculation.activity.period))
      // get only these activities that have this discount
      .map((calculation) => (calculation.activity.discountIds.includes(this.discountDefinition.id) ? calculation.activity.id : null))
      // cleanup: remove nulls
      .filter((activityId): activityId is string => activityId !== null)
  }

  calculate(previousCalculations: Calculation[], userInput: CalculationUserInput) {
    const eligibleActivities = this.chooseEligible(previousCalculations, userInput)

    previousCalculations.map((calculation) => {
      if (!eligibleActivities.includes(calculation.activity.id)) return
      const activityPrice = getPriceFromCalculation(calculation)
      const discountAmount =
        this.discountDefinition.calculationType === 'amount'
          ? this.discountDefinition.value // amount discount
          : fixMathRounding(activityPrice * (this.discountDefinition.value / 100)) // percentage discount
      const memberDiscount: CalculationDiscount = {
        type: this.type,
        name: this.discountDefinition.name,
        id: this.discountDefinition.id,
        input: activityPrice,
        discount: discountAmount,
        output: fixMathRounding(activityPrice - discountAmount),
        discountCalculationType: this.discountDefinition.calculationType,
        discountValue: this.discountDefinition.value
      }
      calculation.discounts = calculation.discounts || []
      calculation.discounts.push(memberDiscount)
      calculation.finalPrice = memberDiscount.output
    })

    return previousCalculations
  }
}
