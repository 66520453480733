import React from 'react'
import { Typography, IconButton } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import { Activity, OccationInstance } from '../../../pure-js/types/GrooverTypes'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'

interface RegistrationOccurrencesProps {
  activity: Activity
}

const RegistrationOccurrences: React.FC<RegistrationOccurrencesProps> = ({ activity }) => {
  const generateOccations = (): OccationInstance[] => {
    if (activity.occations && activity.occations.length > 0) {
      return activity.occations // Use provided occations
    }

    const start = new Date(activity.startDate || '')
    const generatedOccations: OccationInstance[] = []

    if (!activity.noOfOccasions) return generatedOccations

    for (let i = 0; i < activity.noOfOccasions; i++) {
      const occationStart = new Date(start)
      occationStart.setDate(start.getDate() + i * 7) // Weekly intervals

      const occationEnd = new Date(occationStart)
      if (!activity.time) return generatedOccations
      const [hours, minutes] = activity.time.split(':').map(Number)
      occationStart.setHours(hours, minutes)
      occationEnd.setHours(hours, minutes + 55) // 55 minutes duration

      generatedOccations.push({
        startDate: occationStart.toISOString(),
        endDate: occationEnd.toISOString(),
        instructors: []
      })
    }

    return generatedOccations
  }

  const displayOccations = generateOccations()

  return (
    <div className="registration-info">
      <div className="header">
        <Box direction="row" justify="space-between" fullWidth>
          <Typography variant="h5" component="h2" className="header-title">
            {activity.noOfOccasions} tillfällen
          </Typography>
          <Box direction="row" gap="6px" align="center">
            <FigmaText textKey={Texts.externalActivityPageRoomPlace} />{' '}
            <Typography component="p">{activity.place}</Typography>
          </Box>
        </Box>
        {/* <div className="overlay-container">
          <IconButton className="share-button" aria-label="share event" disabled>
            <ShareIcon />
            <Typography className="share-button-text">Lägg till i kalendern</Typography>
          </IconButton>
          <div className="overlay">
            <Typography variant="body2" className="overlay-text">
              Kommer snart
            </Typography>
          </div>*/}
      </div>
      <div className="occations-container">
        <ul className="occations-list">
          {displayOccations.map((occation, index) => (
            <li key={index} className="occation-item">
              <Typography variant="body1">
                {format(new Date(occation.startDate), 'EEE - d MMM - HH:mm', { locale: sv })
                  .replace(/\b[a-zåäö]+\b/g, (word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .replace(/\./g, '')}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default RegistrationOccurrences
