import styled from '@emotion/styled'
import Box from '../Box'
import FigmaText from '../FigmaText'
import { Texts } from '../../../../pure-js/libs/Texts'
import { ActivityCardProps } from '../../types/activity.types'
import { ActivityMetadata } from './ActivityMetadata'
import { getActivityMetadata } from '../../config/activity.config'

export const ActivityCard: React.FC<ActivityCardProps> = ({ activity, onClick, clientColors }) => (
  <StyledActivityCard
    onClick={onClick}
    fullWidth
    gap="8px"
    $type={activity.type}
    $primaryColor={clientColors.primary}
    $secondaryColor={clientColors.secondary}
  >
    <FigmaText text={activity.name} textKey={Texts.externalActivityPageActivityHeader} />
    <ActivityMetadata items={getActivityMetadata(activity)} />
  </StyledActivityCard>
)

const StyledActivityCard = styled(Box)<{
  $type?: string
  $primaryColor?: string
  $secondaryColor?: string
}>`
  color: white;
  border-radius: 8px;
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  margin-bottom: 8px;
  cursor: pointer;
  background: ${(props) => {
    const color = props.$type === 'Course' ? props.$primaryColor : props.$secondaryColor
    return color || 'transparent'
  }};

  &:hover {
    background: ${(props) => {
      const color = props.$type === 'Course' ? props.$primaryColor : props.$secondaryColor
      return color ? `${color}cc` : 'rgba(108, 108, 158, 0.9)'
    }};
  }
`
