import { Dispatch, useCallback } from 'react'
import { RegisterActions } from '../enums/RegisterSteps'
import { CourseRegistrationFormValues } from '../helpers/CourseRegistrationStepOneHelper'
import { Activity, PaymentMethod } from '../../../pure-js/types/GrooverTypes'

export type RegisterActionFunctions = {
  onSaveRegistrationData: (data: CourseRegistrationFormValues) => void
  onOpenStep: (step: number) => void
  onSetFinalPrice: (price: string) => void
  onCompleteRegistration: () => void
  onSetFormErrors: (hasFormErrors: boolean) => void
  onSetSelectedActivities: (selectedActivities: Activity[]) => void
  onSetIsMainUserStudent: (isMainUserStudent: boolean) => void
  onSetIsPartnerStudent: (isPartnerStudent: boolean) => void
  onSetIsMainUserSenior: (isMainUserSenior: boolean) => void
  onSetIsPartnerSenior: (isPartnerSenior: boolean) => void
  onSetEnrollmentId: (enrollmentId: string) => void
  onSetPaymentMethod: (paymentMethod: PaymentMethod) => void
  onSetIsPaid: (isPaid: boolean) => void
}

export const useRegisterActionFunctions = (
  dispatcher: Dispatch<{ type: RegisterActions; payload: unknown }>
): RegisterActionFunctions => ({
  onSaveRegistrationData: useCallback(
    (data: CourseRegistrationFormValues) => dispatcher({ type: RegisterActions.SAVE_REGISTRATION_DATA, payload: data }),
    [dispatcher]
  ),
  onOpenStep: useCallback(
    (step: number) => dispatcher({ type: RegisterActions.SET_ACTIVE_STEP, payload: { activeRegisterStep: step } }),
    [dispatcher]
  ),
  onSetFinalPrice: useCallback(
    (price: string) => dispatcher({ type: RegisterActions.SET_FINAL_PRICE, payload: { finalPrice: price } }),
    [dispatcher]
  ),
  onCompleteRegistration: useCallback(
    () => dispatcher({ type: RegisterActions.SET_IS_REGISTRATION_COMPLETED, payload: true }),
    [dispatcher]
  ),
  onSetFormErrors: useCallback(
    (hasFormErrors: boolean) => dispatcher({ type: RegisterActions.SET_FORM_ERRORS, payload: { hasFormErrors } }),
    [dispatcher]
  ),
  onSetSelectedActivities: useCallback(
    (selectedActivities: Activity[]) =>
      dispatcher({ type: RegisterActions.SET_SELECTED_ACTIVITIES, payload: { selectedActivities } }),
    [dispatcher]
  ),
  onSetIsMainUserStudent: useCallback(
    (isMainUserStudent: boolean) =>
      dispatcher({ type: RegisterActions.SET_IS_MAIN_USER_STUDENT, payload: { isMainUserStudent } }),
    [dispatcher]
  ),
  onSetIsPartnerStudent: useCallback(
    (isPartnerStudent: boolean) =>
      dispatcher({ type: RegisterActions.SET_IS_PARTNER_STUDENT, payload: { isPartnerStudent } }),
    [dispatcher]
  ),
  onSetIsMainUserSenior: useCallback(
    (isMainUserSenior: boolean) =>
      dispatcher({ type: RegisterActions.SET_IS_MAIN_USER_SENIOR, payload: { isMainUserSenior } }),
    [dispatcher]
  ),
  onSetIsPartnerSenior: useCallback(
    (isPartnerSenior: boolean) =>
      dispatcher({ type: RegisterActions.SET_IS_PARTNER_SENIOR, payload: { isPartnerSenior } }),
    [dispatcher]
  ),
  onSetEnrollmentId: useCallback(
    (enrollmentId: string) => dispatcher({ type: RegisterActions.SET_ENROLLMENT_ID, payload: { enrollmentId } }),
    [dispatcher]
  ),
  onSetPaymentMethod: useCallback(
    (paymentMethod: PaymentMethod) =>
      dispatcher({ type: RegisterActions.SET_PAYMENT_METHOD, payload: { paymentMethod } }),
    [dispatcher]
  ),
  onSetIsPaid: useCallback(
    (isPaid: boolean) => dispatcher({ type: RegisterActions.SET_IS_PAID, payload: { isPaid } }),
    [dispatcher]
  )
})
