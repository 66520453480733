import styled from '@emotion/styled'
import { memo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import Box from '../components/Box'
import RegisterStepsHeader from '../components/RegisterStepsHeader'
import CourseRegistrationStep from '../components/CourseRegistrationStep'
import { useActivity } from '../hooks/useActivity'
import { registerReducer } from '../libs/RegisterReducer'
import { useRegisterActionFunctions } from '../hooks/useRegisterActions'
import { DEFAULT_STATE } from '../hooks/useAppState'
import { REGISTER_PAGE_MAX_WIDTH } from '../enums/HardCodedValues'
import { SIXTEEN_PX, TEN_PX } from '../enums/Spacings'
import FigmaText from '../components/FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { stepConfig } from '../helpers/RegisterPageHelper'

const RegisterPage = () => {
  const { activityId } = useParams<{ activityId: string }>()
  const { data: activity } = useActivity(activityId as string)
  const [state, dispatcher] = useReducer(registerReducer, DEFAULT_STATE)

  const actions = useRegisterActionFunctions(dispatcher)

  if (!activity)
    return (
      <Box>
        <FigmaText textKey={Texts.registrationStep5PaymentSwishGenericError} text="Missing activity!" />
      </Box>
    )

  return (
    <Container fullWidth fullHeight fullPadding spacing={TEN_PX} gap={SIXTEEN_PX}>
      <RegisterStepsHeader
        activeRegisterStep={state.activeRegisterStep}
        isRegistrationCompleted={state.isRegistrationCompleted}
      />
      <CourseRegistrationStep stepConfig={stepConfig} state={state} activity={activity} actions={actions} />
    </Container>
  )
}

const Container = styled(Box)`
  max-width: ${REGISTER_PAGE_MAX_WIDTH};
`

export default memo(RegisterPage)
