import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import { TWELVE_PX } from '../enums/Spacings'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import { memo } from 'react'
import { getWeekdayAbbreviation } from '../libs/DayJsHelper'
import ExpandedActivityDetail from './ExpandedActivityDetail'
import { GreyLine } from './StyledComponents'

type Props = {
  activity: Activity
  onOpenGoogleMaps: (e: React.MouseEvent<HTMLDivElement>) => void
}

const ExpandedActivityDetails = ({ activity, onOpenGoogleMaps }: Props) => (
  <Box fullWidth top gap={TWELVE_PX}>
    <ExpandedActivityDetail
      labelTextKey={Texts.registrationStep6SummarySummaryStartdateLabel}
      valueTextKey={Texts.registrationStep6SummaryCssActivitySummaryValue}
      valueText={activity.startDate}
    />

    <GreyLine />

    <ExpandedActivityDetail
      labelTextKey={Texts.registrationStep6SummarySummaryDayAndTimeLabel}
      valueTextKey={Texts.registrationStep6SummaryCssActivitySummaryValue}
      valueText={`${getWeekdayAbbreviation(activity.startDate)} ${activity.time}`}
    />

    <GreyLine />

    <ExpandedActivityDetail
      labelTextKey={Texts.registrationStep6SummarySummaryOccurencesLabel}
      valueTextKey={Texts.registrationStep6SummaryCssActivitySummaryValue}
      valueText={String(activity.noOfOccasions)}
    />

    <GreyLine />

    <ExpandedActivityDetail
      labelTextKey={Texts.registrationStep6SummaryActivitySummaryLocation}
      valueTextKey={Texts.registrationStep6SummaryCssActivitySummaryValue}
      valueText={activity.place ? activity.place : 'N/A'}
    />

    <GreyLine />

    <ExpandedActivityDetail
      labelTextKey={Texts.registrationStep6SummaryActivitySummaryGoogleUrl}
      valueTextKey={Texts.registrationStep6SummaryCssActivitySummaryValue}
      valueText="Link"
      onClick={onOpenGoogleMaps}
    />
  </Box>
)

export default memo(ExpandedActivityDetails)
