import { Discount, DiscountUserInputV1, DiscountUserInputV2 } from './DiscountTypes'
import { StripeEnvironment } from './StripeTypes'
import { SwishConfigEnvironment } from './SwishTypes'
import { BaseObject } from './types'

export type InternalCustomerData = BaseObject & {
  clientId: string
  stripeCustomerId: string
}

export enum PaymentMethod {
  Stripe = 'Stripe',
  BankTransfer = 'BankTransfer',
  Swish = 'Swish',
  Cash = 'Cash',
  Klarna = 'Klarna'
}

export type PaymentMethodFee = {
  method: PaymentMethod
  fee: number
}

export type Client = BaseObject & {
  name: string
  address: string
  contactEmail: string
  bankGiro: string
  homepage: string
  orgNo: string
  logoUrl: string // deprecated, will use logoFullUrl
  logoFullUrl: string
  locations: Location[]
  status: 'active' | 'inactive' | undefined
  paymentMethodFees: PaymentMethodFee[]
  discounts: Discount[]
  swishEnvironment?: SwishConfigEnvironment
  stripeOperator?: string
  stripeEnvironment?: StripeEnvironment
  stripePaymentMethods?: string[]
  hideDiscountsInPublicPage?: boolean
  primaryColor?: string
  secondaryColor?: string
}

export type UpsertClientParams = Pick<Client, 'id'> & Partial<Omit<Client, 'id'>>

export type Location = BaseObject & {
  googleUrl: string
  name: string
  adress: string
  center: {
    lat: number
    lng: number
  }
}

export type ClientPrivate = {
  clientId: string
  stripe?: {
    payment_method_types: string[]
    public_key?: string
    private_key?: string
  }
}

// An activity is a course or an one-time event
export type Activity = BaseObject & {
  // MANDATORY
  clientId: string // The school that uses the system
  type: 'Course' | 'Occasion'
  status: 'active' | 'draft' | 'planned' | 'cancelled' | 'completed'
  name: string
  description: string
  length: number // In minutes ex. 55 min
  price: number
  activityType: 'Couple' | 'Single'
  approvalRequired: boolean
  style: string
  discountIds: string[] // Discount ids (Discounts that apply to this activity; points to client's discounts)
  maxAllowedParticipants: number // How many activities can be made before the course is full
  period: string // ex: “Q4 2024”
  startDate: string // precise date (YYYY-MM-DD) when the course starts
  noOfOccasions: number // 10 for a course
  time: string

  // OPTIONAL
  occations?: OccationInstance[]
  ageGroup?: string // ex. “18+”
  level?: string
  VAT?: number
  currency?: string
  discounts?: Discount[] // DEPRECATED Discounts that apply to this activity
  place?: string // Text for now
  city?: string
  locationId?: string // Reference to Location object
  maxAllowedUnbalance?: number // How many leaders/followers can be unbalanced
  substyle?: string
  colorCode?: string
  reserveListActive?: boolean
  allowDropin?: boolean
  group?: string // group similar courses (ie. Salsa1 for period1 and period2); also allows
}

export type OccationInstance = {
  startDate: string // ex. 2024-10-01 17:00
  endDate: string // ex. 2024-12-01 18:00
  instructors: string[] // List of teacher ids e.g userIds
}

// used to send user data from frontend to backend
export type EnrollmentUserRequest = {
  name: string
  email: string
  phone: string
  type: 'leader' | 'follower'
}

export type EnrollmentPaymentInputRequest = {
  user: 'mainUser' | 'partnerUser'
  method: PaymentMethod
}

// TODO: this is obsolete, use EnrollmentRequestV2
export type EnrollmentRequest = {
  clientId: string
  mainUser: EnrollmentUserRequest
  partnerUser?: EnrollmentUserRequest
  activityIds: string[]
  discountUserInput: DiscountUserInputV1
  paymentInput: EnrollmentPaymentInputRequest[]
}

export type EnrollmentRequestV2 = {
  clientId: string
  mainUser: EnrollmentUserRequest
  partnerUser?: EnrollmentUserRequest
  activityIds: string[]
  discountUserInput: DiscountUserInputV2
  paymentInput: EnrollmentPaymentInputRequest[]
}

export type EnrollmentRequestResponseV2 = {
  enrollmentId: string
}

export type UserEnrollmentPeriod = {
  id: string
  name: string
  period: string
}

// database representation of a user in a course
export type EnrollmentUser = BaseObject &
  Omit<EnrollmentUserRequest, 'type'> & {
    stripeCustomerId?: string
  }

export enum EnrollmentPaymentStatus {
  Unpaid = 'unpaid',
  PartlyPaid = 'partlyPaid',
  Paid = 'paid'
}

export type Enrollment = BaseObject & {
  version?: number
  clientId: string
  mainUser: EnrollmentUserRequest & { id: string }
  partnerUser?: EnrollmentUserRequest & { id: string }
  activities: Activity[]
  status: 'pending' | 'confirmed' | 'active' | 'cancelled' | 'deleted'
  discountUserInput: DiscountUserInputV1 | DiscountUserInputV2
  payments: Payment[] // payments are future operations when doing payments or marking as paid
  paymentStatus: EnrollmentPaymentStatus
  priceDetails: PriceDetails[] // Breakdown of the price
}

// if refund, value is negative
export type Payment = {
  currency: string
  totalAmount: number // Including VAT
  paymentDate?: string // TODO remove, use stripe payment object instead
  paymentRef?: string // ex. Stripe payment id
  paymentMethod: string // PaymentMethod
  source: 'stripe' | 'swish' | 'manual'
  type: 'pay' | 'refund'
}

export type PriceDetails = {
  activityId: string
  originalPrice: number // price before discount; course price
  totalDiscountPrice: number // discount price from all discounts
  finalPrice: number // originalPrice - totalDiscountPrice
  paymentMethod: PaymentMethod
  administrationFee: number // derives from the payment method selected
  discounts?: Discount[] // Discounts used
  discountIds?: string[] // id of discounts used
  appliesTo: string // which user the price applies to (mainUser or partnerUser)
}
