import styled from '@emotion/styled'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { State } from '../hooks/useAppState'
import iconCompleted from '../../public/iconCompleted.svg'
import removeCourseIcon from '../../public/removeCourseIcon.svg'
import addCourseIcon from '../../public/addCourseIcon.svg'
import openCourseDetailsIcon from '../../public/openCourseDetailsIcon.svg'
import iconArrow from '../../public/iconArrow.svg'
import { memo, useEffect, useState } from 'react'
import { ClosedContainer, ContinueButton, OpenContainer } from './StyledComponents'
import { EIGHT_PX, FOUR_PX, SIX_PX, TEN_PX, TWO_PX } from '../enums/Spacings'
import { Link } from 'react-router-dom'
import { getClient, getClientActivities } from '../libs/DBApiHandler'
import dayjs from 'dayjs'
import { BorderRadixes } from '../enums/BorderRadixes'
import { getWeekdayAbbreviation } from '../libs/DayJsHelper'
import PriceContainer from './PriceContainer'
import useDiscountCalculations from '../hooks/useDiscountCalculations'
import { getDateColor } from '../libs/ColorUtils'
import { getFigmaText } from '../libs/TextRepository'
import { getActivePeriodsForUser } from '../libs/CloudFunctionsApiHandler'

type Props = {
  state: State
  activity?: Activity
  onNextStep: () => void
  onOpenStepTwo: () => void
  registerActiveStep: number
  setFinalPrice: (price: string) => void
  onSetSelectedActivities: (selectedActivities: Activity[]) => void
}

const CourseRegistrationStepTwo = ({
  registerActiveStep,
  onNextStep,
  onOpenStepTwo,
  activity,
  state,
  setFinalPrice,
  onSetSelectedActivities
}: Props) => {
  const [modifiedState, setModifiedState] = useState({ ...state }); 
  const [allActivities, setAllActivities] = useState<Activity[] | null>(null)
  const [client, setClient] = useState<Client | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const fetchedActivities = await getClientActivities(activity?.clientId)
        setAllActivities(fetchedActivities)
      } catch (err) {
        console.error(err)
      }
    }

    const fetchClient = async () => {
      if (activity?.clientId) {
        const clientData = await getClient(activity.clientId)
        if (clientData) setClient(clientData)
      }
    }

    if (activity?.id) {
      fetchActivity()
      fetchClient()
    }
  }, [activity?.clientId])

  useEffect(() => {
    if (activity?.id) onSetSelectedActivities([activity])
  }, [activity?.id])

  const { hasMainUser, hasPartner, mainUserPrices, partnerUserPrices, finalPrice } = useDiscountCalculations({
    activity,
    client,
    state
  })

  useEffect(() => {
    // if (!client) return

    // setIsLoading(true)

    // const fetchActivePeriodsForUser = async (email, clientId) => {
    //   if (!email) return []
    //   const response = await getActivePeriodsForUser({email, clientId}, state)
    //   console.log('isMember response', email, clientId, response)
    //   return response
    // }

    // // check if main user and partner user are members
    // Promise.all([
    //   fetchActivePeriodsForUser(state.email, client.id),
    //   fetchActivePeriodsForUser(state.partnerEmail, client.id)
    // ]).then(([mainUserPeriods, partnerUserPeriods]) => {
    //   console.log('isMember response for main user', state.email, mainUserPeriods)
    //   console.log('isMember response for partner user', state.partnerEmail, partnerUserPeriods)
    //   // TODO: this is wrong - not updating main state
    //   setModifiedState({
    //     ...modifiedState,
    //     mainUserActivePeriods: mainUserPeriods,
    //     partnerUserActivePeriods: partnerUserPeriods
    //   })
    //   setIsLoading(false)
    // })
  
  }, [client, state.email, state.partnerEmail])

  const onOpenStep = () => {
    if (registerActiveStep > 2 || state.isRegistrationCompleted) onOpenStepTwo()
  }

  useEffect(() => {
    if (finalPrice) setFinalPrice(finalPrice + ' SEK')
  }, [finalPrice])

  if (isLoading) return <Box top>Loading data</Box>

  return registerActiveStep !== 2 ? (
    <ClosedContainer onClick={onOpenStep}>
      <Box fullWidth direction="row" align="center" gap="15px">
        {(registerActiveStep > 1 || state.isRegistrationCompleted) && hasMainUser && (
          <img src={iconCompleted} width={24} height={24} />
        )}
        <FigmaText
          textKey={
            state?.activeRegisterStep > 2
              ? Texts.registrationHeadersAndStatusHeaderCssDone
              : Texts.registrationHeadersAndStatusHeaderCssNotDone
          }
          text={getFigmaText(Texts.registrationHeadersAndStatusHeaderSelectedCourses)}
        />
      </Box>
    </ClosedContainer>
  ) : (
    <OpenContainer>
      <Box fullWidth direction="row" align="center" gap="15px">
        <Box direction="row" gap="15px">
          {state.isRegistrationCompleted && <img src={iconCompleted} width={24} height={24} />}
          <FigmaText
            textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
            text={getFigmaText(Texts.registrationHeadersAndStatusHeaderSelectedCourses)}
          />
        </Box>
        <StepCircle align="center" justify="center">
          {state?.selectedActivities.length}
        </StepCircle>
      </Box>
      {hasMainUser ? (
        <SelectedCourses fullWidth direction="row" align="flex-start" justify="space-between">
          <Box fullWidth>
            {Object.entries(
              state?.selectedActivities?.reduce(
                (acc, activity) => {
                  const key = activity.group || activity.id
                  return {
                    ...acc,
                    [key]: [...(acc[key] || []), activity]
                  }
                },
                {} as Record<string, Activity[]>
              )
            ).map(([_group, groupEntries]) => (
              <Box
                id="activity-list-item-container"
                key={activity?.id}
                fullWidth
                direction="row"
                align="flex-start"
                justify="flex-start"
                left
                spacing={EIGHT_PX}
                gap={EIGHT_PX}
              >
                <Box id="activivy-list-item-selector" direction="row" align="center" gap={TWO_PX} top spacing={TWO_PX}>
                  {!state.isRegistrationCompleted && (
                    <InvisibleButton
                      onClick={() =>
                        onSetSelectedActivities(
                          state?.selectedActivities.filter((a) => !groupEntries.map((a) => a.id).includes(a.id))
                        )
                      }
                    >
                      <img src={removeCourseIcon} width={16} height={16} />
                    </InvisibleButton>
                  )}
                </Box>
                <Box id="activity-list-main-container" fullWidth>
                  <Box
                    id="activity-list-item-header"
                    fullWidth
                    direction="row"
                    align="flex-start"
                    gap={SIX_PX}
                    top
                    spacing={TWO_PX}
                  >
                    {groupEntries.map((selectedActivity) => (
                      <ColorCircle dateColor={getDateColor(selectedActivity?.period || '')} />
                    ))}

                    <FigmaText
                      textKey={Texts.registrationStep3ActivitiesSelectedActivityName}
                      text={groupEntries[0]?.name}
                    />
                  </Box>
                  {groupEntries.map((selectedActivity) => (
                    <Box key={selectedActivity.id} id="activity-list-main-container" fullWidth>
                      <Box direction="row" align="center" gap={FOUR_PX} fullWidth>
                        <Box id="activity-list-startdate" direction="row" align="center" gap={FOUR_PX}>
                          <StartDate fullPadding spacing={FOUR_PX}>
                            <FigmaText
                              textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                              text={dayjs(selectedActivity?.startDate).format('D/M')}
                            />
                          </StartDate>
                        </Box>
                        <Box direction="row" gap={FOUR_PX} fullWidth>
                          <FigmaText
                            textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                            text={getWeekdayAbbreviation(selectedActivity?.startDate)}
                          />
                          <FigmaText
                            textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                            text={selectedActivity?.time}
                          />
                          {groupEntries.length > 1 && !state.isRegistrationCompleted && (
                            <InvisibleButton
                              onClick={() =>
                                onSetSelectedActivities(
                                  state?.selectedActivities.filter((a) => a.id !== selectedActivity.id)
                                )
                              }
                            >
                              <img src={removeCourseIcon} width={16} height={16} />
                            </InvisibleButton>
                          )}
                        </Box>
                        <Link style={{ paddingTop: FOUR_PX }} to={`/activity/${selectedActivity?.id}`} target="_blank">
                          <img src={openCourseDetailsIcon} width={16} height={16} />
                        </Link>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}

            <Box fullWidth top>
              {!state.isRegistrationCompleted &&
                (allActivities ?? []).filter(
                  (activity) => !(state?.selectedActivities ?? []).some((a) => a.id === activity?.id)
                ).length > 0 && (
                  <Box bottom spacing={TEN_PX}>
                    <FigmaText textKey={Texts.registrationStep3ActivitiesHeaderStep3AddMoreCourses} />
                  </Box>
                )}

              {!state.isRegistrationCompleted &&
                allActivities
                  ?.filter((activity) => !state?.selectedActivities?.some((a) => a.id === activity?.id))
                  ?.sort((a, b) => new Date(a.startDate || '').getTime() - new Date(b.startDate || '').getTime())
                  ?.map((activity) => (
                    <ActivityListItem
                      id="activity-list-item-container"
                      key={activity?.id}
                      fullWidth
                      direction="row"
                      align="flex-start"
                      justify="flex-start"
                      left
                      spacing={EIGHT_PX}
                      gap={EIGHT_PX}
                    >
                      <Box
                        id="activivy-list-item-selector"
                        direction="row"
                        align="center"
                        gap={TWO_PX}
                        top
                        spacing={TWO_PX}
                      >
                        <InvisibleButton
                          onClick={() => onSetSelectedActivities([...state?.selectedActivities, activity])}
                        >
                          <img src={addCourseIcon} width={16} height={16} />
                        </InvisibleButton>
                      </Box>
                      <Box id="activity-list-main-container" fullWidth>
                        <ActivityItemTitle id="activity-list-item-title" direction="row" align="center" gap={SIX_PX}>
                          <ColorCircle dateColor={getDateColor(activity?.period || '')} />

                          <FigmaText
                            textKey={Texts.registrationStep3ActivitiesSelectedActivityName}
                            text={`${activity?.name}`}
                          />
                        </ActivityItemTitle>
                        <Box
                          id="activity-list-item-metadata"
                          fullWidth
                          justify="space-between"
                          direction="row"
                          align="center"
                          gap={FOUR_PX}
                        >
                          <Box direction="row" align="center" gap={FOUR_PX}>
                            <Box direction="row" align="center" gap={FOUR_PX}>
                              <StartDate fullPadding spacing={FOUR_PX}>
                                <FigmaText
                                  textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                                  text={dayjs(activity?.startDate).format('D/M')}
                                />
                              </StartDate>
                            </Box>
                            <Box id="activity-list-item-weekday" direction="row" gap={FOUR_PX}>
                              <FigmaText
                                textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                                text={getWeekdayAbbreviation(activity?.startDate)}
                              />
                              <FigmaText
                                textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                                text={activity?.time}
                              />
                            </Box>
                          </Box>
                          <Link style={{ paddingTop: FOUR_PX }} to={`/activity/${activity?.id}`} target="_blank">
                            <img src={openCourseDetailsIcon} width={16} height={16} />
                          </Link>
                        </Box>
                      </Box>
                    </ActivityListItem>
                  ))}
            </Box>
          </Box>
          <PriceContainer
            mainUserPrices={mainUserPrices}
            partnerUserPrices={partnerUserPrices}
            hasPartner={hasPartner}
            finalPrice={finalPrice}
            state={state}
          />
        </SelectedCourses>
      ) : (
        <Box top>Vänligen ange dina uppgifter i steg 1.</Box>
      )}
      <Box fullWidth direction="row" justify="flex-end" top>
        {!state.isRegistrationCompleted ? (
          <ContinueButton onClick={onNextStep} isDisabled={!(hasMainUser && state?.selectedActivities.length > 0)}>
            <FigmaText textKey={Texts.registrationCtactaContinue} />
            <img src={iconArrow} width={16} height={16} />
          </ContinueButton>
        ) : null}
      </Box>
    </OpenContainer>
  )
}

const ActivityItemTitle = styled(Box)`
  width: 100%;
`
const ActivityListItem = styled(Box)`
  &:hover {
    background-color: #f5f5f5;
  }
`

const SelectedCourses = styled(Box)`
  gap: 24px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const InvisibleButton = styled(Box)`
  cursor: pointer;
`

const StepCircle = styled(Box)`
  width: 30px;
  height: 30px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  background-color: rgba(30, 56, 126, 1);
`

const UserContainer = styled(Box)`
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
`

const Discount = styled(Box)`
  background-color: #70ab37;
  border-radius: 8px;
  padding: 2px 8px;
`

const StartDate = styled(Box)`
  border-radius: ${BorderRadixes.regular};
`

const ColorCircle = styled(Box)<{ dateColor?: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => props.dateColor || '#EFEBEB'};
  flex-shrink: 0;
`

export default memo(CourseRegistrationStepTwo)
