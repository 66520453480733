import styled from '@emotion/styled'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { Calculation, CalculationDiscount } from '../../../pure-js/libs/calculateLogic/CalculateDiscountTypes'
import { getPriceFromCalculations } from '../../../pure-js/libs/calculateLogic/CalculateDiscountHelpers'
import { FOUR_PX, EIGHT_PX, TWELVE_PX } from '../enums/Spacings'
import PriceContainerActivity from './PriceContainerActivity'

type Props = {
  userPrices: Calculation[]
  userName: string
}

const UserPricing = ({ userPrices, userName }: Props) => {
  // Calculate all discounts up front
  const { studentDiscount, seniorDiscount, coupleDiscount } = userPrices.reduce(
    (acc, calculation) => {
      if (calculation.discounts) {
        calculation.discounts.forEach((discount) => {
          if (discount.type === 'student') {
            acc.studentDiscount += discount.discount / 100
          } else if (discount.type === 'senior') {
            acc.seniorDiscount += discount.discount / 100
          } else if (discount.type === 'couples') {
            acc.coupleDiscount += discount.discount / 100
          }
        })
      }
      return acc
    },
    { studentDiscount: 0, seniorDiscount: 0, coupleDiscount: 0 }
  )

  const totalPrice = getPriceFromCalculations(userPrices) / 100

  const getDiscount = (discountType: string) => {
    const calculation = userPrices[0]
    if (calculation?.discounts) {
      return calculation.discounts.find((discount) => discount.type === discountType)
    }
    return undefined
  }

  return (
    <Box fullWidth bottom spacing={EIGHT_PX}>
      <FigmaText textKey={Texts.componentsPricingControlPricingAttendeeGroup} text={userName.toUpperCase()} />

      <UserContainer fullWidth fullPadding spacing="16px">
        {/* Iterate through activitites */}
        {Object.entries(
          userPrices
            ?.sort((a, b) => b.finalPrice - a.finalPrice)
            .reduce<Record<string, Calculation[]>>((acc, calculation) => {
              const key = calculation.activity.group || calculation.activity.id
              return {
                ...acc,
                [key]: [...(acc[key] || []), calculation]
              }
            }, {})
        ).map(([group, calculations]) => (
          <Box fullWidth key={calculations[0].activity.id}>
            <PriceContainerActivity calculations={calculations} />
            <Box
              fullWidth
              style={{
                borderBottom: '1px solid #eee',
                paddingTop: '4px',
                paddingBottom: '4px'
              }}
            />
          </Box>
        ))}

        {/* Show user discounts  */}
        {studentDiscount > 0 && (
          <DiscountRow label="Studentrabatt" amount={studentDiscount} discount={getDiscount('student')} />
        )}
        {seniorDiscount > 0 && (
          <DiscountRow label="Seniorrabatt" amount={seniorDiscount} discount={getDiscount('senior')} />
        )}
        {coupleDiscount > 0 && (
          <DiscountRow label="Parrabatt" amount={coupleDiscount} discount={getDiscount('couples')} />
        )}

        {/* Show user total price */}
        <Box alignSelf="flex-end" top spacing={TWELVE_PX} style={{ fontStyle: 'italic' }}>
          <FigmaText textKey={Texts.componentsPricingControlCssUserSubtotal} text={`${totalPrice} SEK`} />
        </Box>
      </UserContainer>
    </Box>
  )
}

const DiscountRow = ({
  label,
  amount,
  discount
}: {
  label: string
  amount: number
  discount?: CalculationDiscount
}) => (
  <Box fullWidth direction="row" align="center" justify="space-between" top bottom spacing={TWELVE_PX}>
    <FigmaText textKey={Texts.componentsPricingControlStudentDiscountRow} text={label} />
    <Box direction="row" align="center" gap={FOUR_PX}>
      {/* Show relative discount value if percentage (eg. -> 10% <- (100 SEK)) */}
      {discount?.discountCalculationType === 'percentage' && (
        <FigmaText textKey={Texts.componentsPricingControlDiscountValue} text={`${discount?.discountValue || 0}%`} />
      )}
      <Discount fullPadding spacing={FOUR_PX}>
        <FigmaText textKey={Texts.codeTablesRegTypeCouple} text={`-${String(amount)} SEK`} />
      </Discount>
    </Box>
  </Box>
)

const UserContainer = styled(Box)`
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
`

export const Discount = styled(Box)`
  background-color: #70ab37;
  border-radius: 8px;
  padding: 2px 8px;
  min-width: 80px;
  text-align: right;
  white-space: nowrap;
`

export default UserPricing
