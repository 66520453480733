import * as Yup from 'yup'

export const PhoneRegExp = /^\+?\d{1,3}\d{7,10}$/
export const EMAIL_REGEX = /\S+@\S+\.\S+/

export const isValidEmail = (email: string) => {
  try {
    return EMAIL_REGEX.test(email)
  } catch (err) {
    return false
  }
}

export type CourseRegistrationFormValues = {
  name: string
  phoneNumber: string
  email: string
  role: 'leader' | 'follower' | 'both'
  partnerName: string
  partnerPhoneNumber: string
  partnerEmail: string
  partnerRole: 'leader' | 'follower' | 'both'
}

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Namn är obligatoriskt'),
  phoneNumber: Yup.string()
    .matches(PhoneRegExp, 'Telefonnummer är ogiltigt')
    .required('Telefonnummer är obligatoriskt'),
  email: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  role: Yup.string().required()
})

export const ValidationSchemaPartner = Yup.object().shape({
  name: Yup.string().required('Namn är obligatoriskt'),
  phoneNumber: Yup.string()
    .matches(PhoneRegExp, 'Telefonnummer är ogiltigt')
    .required('Telefonnummer är obligatoriskt'),
  email: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  role: Yup.string().required(),
  partnerName: Yup.string().required('Namn är obligatoriskt'),
  partnerPhoneNumber: Yup.string()
    .matches(PhoneRegExp, 'Telefonnummer är ogiltigt')
    .required('Telefonnummer är obligatoriskt'),
  partnerEmail: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  partnerRole: Yup.string().required()
})
