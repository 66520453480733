import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material'
import StylesProvider from '@mui/styles/StylesProvider'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter } from 'react-router-dom'
import { SNACK_BAR_PROPS } from '../../../pure-js/libs/Consts'
import { Context, useAppStateContext } from '../hooks/useAppState'
import '../libs/Fonts'
import theme from '../libs/theme'
import ErrorBoundaryProvider from './ErrorBoundaryProvider'

import { Toaster } from 'react-hot-toast'
import { OnMountApp } from '../hooks/useOnMountApp'
import { Helmet } from './Helmet'
import Box from './Box'
import NavigationPublicRoutes from './NavigationPublicRoutes'

const App = () => {
  const context = useAppStateContext()

  return (
    <ErrorBoundaryProvider>
      <StylesProvider injectFirst>
        <SnackbarProvider {...SNACK_BAR_PROPS}>
          <Context.Provider value={context}>
            <BrowserRouter>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Helmet titleTemplate="%s - Groover" />
                  <OnMountApp />
                  <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 2000 }} />
                  <Box fullWidth fullHeight align="center">
                    <NavigationPublicRoutes />
                  </Box>
                </ThemeProvider>
              </StyledEngineProvider>
            </BrowserRouter>
          </Context.Provider>
        </SnackbarProvider>
      </StylesProvider>
    </ErrorBoundaryProvider>
  )
}

export default App
