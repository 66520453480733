import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import { useForm } from '../hooks/useForm'
import FigmaText from './FigmaText'
import { memo, useEffect } from 'react'
import iconCompleted from '../../public/iconCompleted.svg'
import { State } from '../hooks/useAppState'
import {
  CourseRegistrationFormValues,
  ValidationSchema,
  ValidationSchemaPartner
} from '../helpers/CourseRegistrationStepOneHelper'
import CourseRegistrationStepOneNoPartnerForm from './CourseRegistrationStepOneNoPartnerForm'
import CourseRegistrationStepOnePartnerForm from './CourseRegistrationStepOnePartnerForm'
import { ClosedContainer } from './StyledComponents'
import { getFigmaText } from '../libs/TextRepository'
import usePartnerAndRoles from '../hooks/usePartnerAndRoles'

type Props = {
  state: State
  onNextStep: () => void
  onOpenStepOne: () => void
  registerActiveStep: number
  onSetFormErrors: (hasFormErrors: boolean) => void
  onSetIsPartnerSenior: (isPartnerSenior: boolean) => void
  onSetIsPartnerStudent: (isPartnerStudent: boolean) => void
  onSetIsMainUserSenior: (isMainUserSenior: boolean) => void
  onSetIsMainUserStudent: (isMainUserStudent: boolean) => void
  onSaveRegistrationData: (data: CourseRegistrationFormValues) => void
}

const CourseRegistrationStepOne = ({
  state,
  onNextStep,
  onOpenStepOne,
  registerActiveStep,
  onSetFormErrors,
  onSetIsPartnerSenior,
  onSetIsPartnerStudent,
  onSetIsMainUserSenior,
  onSetIsMainUserStudent,
  onSaveRegistrationData
}: Props) => {
  const { hasPartner, setHasPartner, role, partnerRole, changeRole, handleRemovePartner } = usePartnerAndRoles()

  const formProps = useForm<CourseRegistrationFormValues>(hasPartner ? ValidationSchemaPartner : ValidationSchema, {
    defaultValues: {
      name: '',
      phoneNumber: '',
      email: '',
      role: 'leader',
      partnerName: '',
      partnerPhoneNumber: '',
      partnerEmail: '',
      partnerRole: 'follower'
    }
  })

  useEffect(() => {
    if (Object.keys(formProps.formState.errors).length > 0) {
      onSetFormErrors(true)
    } else {
      onSetFormErrors(false)
    }
  }, [Object.keys(formProps.formState.errors).length])

  const onSubmit = (data: CourseRegistrationFormValues) => {
    onSaveRegistrationData({ ...data, role, partnerRole })
    onNextStep()
  }

  return registerActiveStep !== 1 ? (
    <ClosedContainer onClick={registerActiveStep > 1 ? onOpenStepOne : undefined}>
      <Box fullWidth direction="row" align="center" gap="15px">
        {/* Mark the step as completed if the form is submitted. */}
        {state.name && state.email && state.phoneNumber && <img src={iconCompleted} width={24} height={24} />}
        <FigmaText
          textKey={
            state?.activeRegisterStep > 1
              ? Texts.registrationHeadersAndStatusHeaderCssDone
              : Texts.registrationHeadersAndStatusHeaderCssActive
          }
          text={getFigmaText(Texts.registrationHeadersAndStatusHeaderAttendee)}
        />
      </Box>
    </ClosedContainer>
  ) : (
    <>
      <CourseRegistrationStepOneNoPartnerForm
        state={state}
        formProps={formProps}
        role={role}
        changeRole={(isPartnerRole, newRole) => changeRole(isPartnerRole, newRole)}
        hasPartner={hasPartner}
        setHasPartner={setHasPartner}
        onSubmit={onSubmit}
        onSetIsMainUserStudent={onSetIsMainUserStudent}
        onSetIsMainUserSenior={onSetIsMainUserSenior}
      />
      {hasPartner ? (
        <CourseRegistrationStepOnePartnerForm
          state={state}
          formProps={formProps}
          partnerRole={partnerRole}
          changeRole={(isPartnerRole, newRole) => changeRole(isPartnerRole, newRole)}
          handleRemovePartner={() => handleRemovePartner(formProps)}
          onSubmit={onSubmit}
          onSetIsPartnerStudent={onSetIsPartnerStudent}
          onSetIsPartnerSenior={onSetIsPartnerSenior}
        />
      ) : null}
    </>
  )
}

export default memo(CourseRegistrationStepOne)
