import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import Box from './Box'
import { Discount } from '../../../pure-js/types/DiscountTypes'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import RegistrationDiscountDisplay from './RegistrationDiscountDisplay'

interface ActivityInfoProps {
  activity: Activity
  client?: Client
}

const RegistrationPrice: React.FC<ActivityInfoProps> = ({ activity, client }) => {
  const [matchedDiscounts, setMatchedDiscounts] = useState<Discount[]>([])

  const getMatchedDiscounts = (): Discount[] => {
    if (!client || !activity.discountIds) return []

    return activity.discountIds
      .map((discountId) => client.discounts.find((discount) => discount.id === discountId))
      .filter(Boolean) as Discount[]
  }

  useEffect(() => {
    if (activity && client) {
      setMatchedDiscounts(getMatchedDiscounts())
    }
  }, [activity, client])

  return (
    <SegmentContainer fullWidth>
      <HeaderContainer>
        <Typography variant="h5" component="h2">
          Pris
        </Typography>
      </HeaderContainer>
      <PriceText>{activity.price ? `${activity.price / 100} SEK` : 'Gratis'}</PriceText>
      {client && client.hideDiscountsInPublicPage ? null : (
        <RegistrationDiscountDisplay matchedDiscounts={matchedDiscounts} />
      )}
    </SegmentContainer>
  )
}

const SegmentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
`

const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
`

const PriceText = styled(Box)`
  margin-top: 8px;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
`

export default RegistrationPrice
