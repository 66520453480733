import { memo } from 'react'
import { TextKey } from '../../../pure-js/types/Antiloop'
import Box from './Box'
import { SIX_PX } from '../enums/Spacings'
import styled from '@emotion/styled'
import { BorderRadixes } from '../enums/BorderRadixes'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'

const RegisterStep = ({
  index,
  step,
  isRegistrationCompleted
}: {
  index: number
  step: { textKey: TextKey; isActive: boolean; isCompleted: boolean }
  isRegistrationCompleted: boolean
}) => (
  <Box direction="row" align="center" gap={SIX_PX}>
    <StepCircle
      align="center"
      justify="center"
      isActive={!isRegistrationCompleted && step.isActive}
      isCompleted={isRegistrationCompleted || step.isCompleted}
    >
      {index + 1}
    </StepCircle>
    <TextBox
      isActive={!isRegistrationCompleted && step.isActive}
      isCompleted={isRegistrationCompleted || step.isCompleted}
    >
      <FigmaText
        styleTextKey={
          step.isActive
            ? Texts.registrationHeadersAndStatusHeaderCssActive
            : step.isCompleted
              ? Texts.registrationHeadersAndStatusHeaderCssDone
              : Texts.registrationHeadersAndStatusHeaderCssNotDone
        }
        textKey={step.textKey}
      />
    </TextBox>
  </Box>
)

const StepCircle = styled(Box)<{ isActive: boolean; isCompleted: boolean }>`
  width: 30px;
  height: 30px;
  color: white;
  flex-shrink: 0;
  font-weight: bold;
  border-radius: ${BorderRadixes.circle};
  background-color: ${({ isActive, isCompleted }) => (isActive ? '#6AAB37' : isCompleted ? '#6AAB37' : '#d9d9d9')};
`

const TextBox = styled(Box)<{ isActive: boolean; isCompleted: boolean }>`
  @media (max-width: 574px) {
    display: none;
  }
  opacity: ${({ isActive, isCompleted }) => (isActive || isCompleted ? 1 : 0.5)};
`

export default memo(RegisterStep)
