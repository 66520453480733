import React from 'react'
import { Routes, Route } from 'react-router-dom'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { RootPage } from '../pages/SignInPage'
import SandboxPage from './SandboxPage'
import { ActivityDetailPage } from '../pages/ActivityDetailPage'
import IframePage from '../pages/IframePage'
import StaticDemoPage from '../pages/StaticDemoPage'
import PayPage from '../pages/PayPage'
import PaySuccessPage from '../pages/PaySuccessPage'
import RegisterPage from '../pages/RegisterPage'
import SwishPayPage from '../pages/SwishPayPage'
import { PublicActivitiesPage } from '../pages/PublicActivitiesPage'

const NavigationPublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={RoutePath.ROOT} Component={RootPage} />
      <Route path={RoutePath.ACTIVITY_DETAIL} Component={ActivityDetailPage} />
      <Route path={RoutePath.SANDBOX} Component={SandboxPage} />
      <Route path={RoutePath.IFRAME} Component={IframePage} />
      <Route path={RoutePath.DEMOPAGE} Component={StaticDemoPage} />
      <Route path={RoutePath.PAY} Component={PayPage} />
      <Route path={RoutePath.PAY_SUCCESS} Component={PaySuccessPage} />
      <Route path={RoutePath.REGISTER} Component={RegisterPage} />
      <Route path={RoutePath.SWISH_PAY} Component={SwishPayPage} />
      <Route path={RoutePath.PUBLIC_ACTIVITIES} Component={PublicActivitiesPage} />
    </Routes>
  )
}

export default NavigationPublicRoutes
