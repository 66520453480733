import React from 'react'
import { Typography, IconButton } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

interface RegistrationLocationProps {
  customer: Client
  activity: Activity
}

const RegistrationLocation: React.FC<RegistrationLocationProps> = ({ customer, activity }) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyDqP2HzJj8g82cwrNDF0YEoVhh26KwDUZs'

  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  }

  const location = customer.locations.find((loc) => loc.id === activity.locationId)

  return (
    <div className="registration-info">
      <div className="header">
        <Typography variant="h5" component="h2" className="header-title">
          Plats
        </Typography>
        <IconButton
          className="share-button"
          aria-label="share event"
          onClick={() => {
            location?.googleUrl && window.open(location.googleUrl, '_blank')
          }}
        >
          <ShareIcon />
          <Typography className="share-button-text">Öppna i Google Maps</Typography>
        </IconButton>
      </div>
      <Typography variant="body1">
        <strong>{location?.name}</strong>
        <br />
        {location?.adress}
      </Typography>

      {googleMapsApiKey && location?.center && (
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap mapContainerStyle={mapContainerStyle} center={location.center} zoom={17}>
            <Marker position={location.center} />
          </GoogleMap>
        </LoadScript>
      )}
    </div>
  )
}

export default RegistrationLocation
