import { createTheme } from '@mui/material'
import { MainColor, SecondayColor, TertiaryColor, White } from '../../../pure-js/libs/Colors'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

export const DefaultMaterialTheme = {
  palette: {
    primary: createColor(MainColor),
    secondary: createColor(SecondayColor),
    tiertary: createColor(TertiaryColor),
    white: createColor(White)
  },
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif' // Add your font globally
  },
  overrides: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '32px', // Rounder button
          padding: '10px 24px', // Adjust padding for better look
          textTransform: 'none', // Keep text as-is (no uppercase)
          fontWeight: 600 // Slightly bolder text
        },
        containedPrimary: {
          backgroundColor: '#3B59EC', // Blue color
          color: '#ffffff', // White text
          '&:hover': {
            backgroundColor: '#1565c0' // Darker blue on hover
          }
        }
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#000'
      }
    }
  }
}

const theme = createTheme(DefaultMaterialTheme)

export default theme
