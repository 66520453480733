import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { TextField, MenuItem } from '@mui/material'
import styled from '@emotion/styled'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import { getActivitiesByCustomerId, getClient } from '../libs/DBApiHandler'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { ActivityCard } from '../components/activities/ActivityCard'
import { GroupByOption, groupActivities } from '../utils/activityUtils'
import Box from '../components/Box'
import FigmaText from '../components/FigmaText'
import { GROUP_BY_OPTIONS, CONTAINER_MAX_WIDTH } from '../config/activity.config'

export const PublicActivitiesPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>()
  const navigate = useNavigate()
  const [activities, setActivities] = useState<Activity[]>([])
  const [filterText, setFilterText] = useState('')
  const [groupBy, setGroupBy] = useState<GroupByOption>('style')
  const [logoUrl, setLogoUrl] = useState<string>()
  const [clientColors, setClientColors] = useState<{ primary?: string; secondary?: string }>({})

  useEffect(() => {
    const fetchData = async () => {
      const [activitiesResult, client] = await Promise.all([
        getActivitiesByCustomerId(clientId || ''),
        getClient(clientId || '')
      ])

      setActivities(activitiesResult.filter((a: Activity) => a.status === 'active'))

      if (client) {
        if (client.logoFullUrl) {
          setLogoUrl(client.logoFullUrl)
        } else if (client.logoUrl) {
          setLogoUrl(`/assets/logos/${client.logoUrl}`)
        }
        setClientColors({
          primary: client.primaryColor || '#2A569D',
          secondary: client.secondaryColor || '#1D5E11'
        })
      } else {
        setClientColors({
          primary: '#2A569D',
          secondary: '#1D5E11'
        })
      }
    }
    fetchData()
  }, [clientId])

  const filteredActivities = useMemo(() => {
    return activities.filter((activity) => activity.name.toLowerCase().includes(filterText.toLowerCase()))
  }, [activities, filterText])

  const groupedActivities = useMemo(() => {
    return groupActivities(filteredActivities, groupBy)
  }, [filteredActivities, groupBy])

  const handleActivityClick = (activityId: string) => {
    navigate(`/activity/${activityId}`, {
      state: { from: 'PublicActivitiesPage' }
    })
  }

  return (
    <Container fullWidth>
      {logoUrl && (
        <LogoContainer fullWidth>
          <Logo src={logoUrl} alt="Client logo" />
        </LogoContainer>
      )}
      <FilterContainer direction="row" fullWidth mobileColumn>
        <StyledTextField
          label={getFigmaText(Texts.adminGeneralSearchHelperText)}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          fullWidth
        />
        <StyledTextField
          select
          label={getFigmaText(Texts.adminGeneralInputFieldsGroupByLabel)}
          value={groupBy}
          onChange={(e) => setGroupBy(e.target.value as GroupByOption)}
        >
          {GROUP_BY_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {getFigmaText(Texts[option.label])}
            </MenuItem>
          ))}
        </StyledTextField>
      </FilterContainer>
      <ActivitiesContainer fullWidth>
        {Object.entries(groupedActivities).map(([key, activities]) => (
          <Section key={key} fullWidth clientColors={clientColors}>
            <FigmaText text={key} textKey={Texts.externalActivityPageSectionHeader} />
            {activities.map((activity) => {
              return (
                <ActivityCard
                  key={activity.id}
                  activity={activity}
                  onClick={() => handleActivityClick(activity.id)}
                  clientColors={clientColors}
                />
              )
            })}
          </Section>
        ))}
      </ActivitiesContainer>
    </Container>
  )
}

const Container = styled(Box)`
  max-width: ${CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  padding: 20px;
  background: rgba(224, 217, 254, 0.03);
`

const FilterContainer = styled(Box)`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`

const StyledTextField = styled(TextField)`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  min-width: 200px;
`

const ActivitiesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`

const Section = styled(Box)<{ clientColors: { primary?: string; secondary?: string } }>`
  margin-bottom: 24px;
  font-size: 16px;
`

const LogoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`

const Logo = styled.img`
  max-height: 80px;
  object-fit: contain;
`
