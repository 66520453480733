import { Activity } from '../../../pure-js/types/GrooverTypes'
import { StepConfig } from '../helpers/RegisterPageHelper'
import { State } from '../hooks/useAppState'
import { RegisterActionFunctions } from '../hooks/useRegisterActions'

type Props = {
  state: State
  activity: Activity
  stepConfig: StepConfig[]
  actions: RegisterActionFunctions
}

const CourseRegistrationStep = ({ state, activity, stepConfig, actions }: Props) => {
  return stepConfig.map(({ id, component: StepComponent, props }) => {
    return <StepComponent key={`registration-step-${id}`} {...props(state, activity, actions)} />
  })
}

export default CourseRegistrationStep
