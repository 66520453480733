import styled from '@emotion/styled'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { memo, useState } from 'react'
import { saveUserRegistrationV2 } from '../libs/CloudFunctionsApiHandler'
import { Activity, EnrollmentRequestV2, PaymentMethod } from '../../../pure-js/types/GrooverTypes'
import { State } from '../hooks/useAppState'
import Spinner from './Spinner'
import iconCompleted from '../../public/iconCompleted.svg'
import iconArrow from '../../public/iconArrow.svg'
import { COURSELY_PRIVACY_POLICY, COURSELY_REGISTRATION_RULES } from '../enums/HardCodedValues'
import { ClosedContainer, ContinueButton, OpenContainer } from './StyledComponents'
import { getFigmaText } from '../libs/TextRepository'

type Props = {
  state: State
  activity?: Activity
  onNextStep: () => void
  registerActiveStep: number
  onOpenStepThree: () => void
  onSetEnrollmentId: (enrollmentId: string) => void
}

const CourseRegistrationStepThree = ({
  registerActiveStep,
  onNextStep,
  onOpenStepThree,
  state,
  activity,
  onSetEnrollmentId
}: Props) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const hasPartner = state.partnerName && state.partnerEmail && state.partnerPhoneNumber && state.partnerRole
  const hasMainUser = !!state.name && !!state.email && !!state.phoneNumber

  const handleCheckboxChange = () => {
    if (!state.isRegistrationCompleted) return setIsCheckboxChecked(!isCheckboxChecked)
  }

  const onFinally = () => {
    setIsLoading(false)
  }

  const handleContinue = () => {
    if (!isCheckboxChecked || !activity?.clientId) return

    setIsLoading(true)

    const mainUserRegistration: EnrollmentRequestV2 = {
      clientId: activity.clientId,
      mainUser: {
        name: state.name,
        email: state.email,
        phone: state.phoneNumber,
        type: state.role
      },
      activityIds: [...state?.selectedActivities.map((activity) => activity.id)],
      paymentInput: [{ user: 'mainUser' as 'mainUser', method: PaymentMethod.BankTransfer }],
      discountUserInput: {
        coursesAmount: state?.selectedActivities.length,
        participantsAmount: 1,
        mainUser: {
          isStudent: state.isMainUserStudent,
          isSenior: state.isMainUserSenior,
          userActivePeriods: state.mainUserActivePeriods
        }
      }
    }

    const registrationData: EnrollmentRequestV2 = hasPartner
      ? {
          ...mainUserRegistration,
          partnerUser: {
            name: state.partnerName as string,
            email: state.partnerEmail as string,
            phone: state.partnerPhoneNumber as string,
            type: state.partnerRole
          },
          discountUserInput: {
            coursesAmount: state?.selectedActivities.length,
            participantsAmount: 2,
            mainUser: {
              isStudent: state.isMainUserStudent,
              isSenior: state.isMainUserSenior,
              userActivePeriods: state.mainUserActivePeriods
            },
            partnerUser: {
              isStudent: state.isPartnerStudent,
              isSenior: state.isPartnerSenior,
              userActivePeriods: state.partnerUserActivePeriods
            }
          }
        }
      : mainUserRegistration

    saveUserRegistrationV2(registrationData, state)
      .then((response) => {
        if (response.enrollmentId) {
          onSetEnrollmentId(response.enrollmentId)
        }
      })
      .then(onNextStep)
      .catch((err) => console.error(err))
      .finally(onFinally)
  }

  const onOpenStep = () => {
    if (registerActiveStep > 3 || state.isRegistrationCompleted) onOpenStepThree()
  }

  const onOpenExternalLink = (e, url: string) => {
    e.stopPropagation()
    window.open(url, '_blank')
  }

  return registerActiveStep !== 3 ? (
    <ClosedContainer onClick={onOpenStep}>
      <Box fullWidth direction="row" align="center" gap="15px">
        {(registerActiveStep > 3 || state.isRegistrationCompleted) && (
          <img src={iconCompleted} width={24} height={24} />
        )}
        <FigmaText
          textKey={
            state?.activeRegisterStep > 2
              ? Texts.registrationHeadersAndStatusHeaderCssDone
              : Texts.registrationHeadersAndStatusHeaderCssNotDone
          }
          text={getFigmaText(Texts.registrationHeadersAndStatusHeaderSummaryAndApproval)}
        />
      </Box>
    </ClosedContainer>
  ) : (
    <OpenContainer>
      <Box gap="16px">
        <Box direction="row" gap="15px">
          {state.isRegistrationCompleted && <img src={iconCompleted} width={24} height={24} />}
          <FigmaText
            textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
            text={getFigmaText(Texts.registrationHeadersAndStatusHeaderSummaryAndApproval)}
          />
        </Box>
        <FigmaText textKey={Texts.registrationStep4ApprovalInfoApprovalPositive} />
      </Box>

      <Box gap="8px">
        <Box direction="row" align="center" gap="8px">
          <span style={{ color: '#28a745', fontSize: '18px' }}>✔</span>
          <FigmaText textKey={Texts.registrationStep4ApprovalInfoApprovalStep2} />
        </Box>
        <Box direction="row" align="center" gap="8px">
          <span style={{ color: '#28a745', fontSize: '18px' }}>✔</span>
          <FigmaText textKey={Texts.registrationStep4ApprovalInfoApprovalStep3} />
        </Box>
      </Box>

      <Link fullWidth direction="row" align="center" gap="8px" onClick={handleCheckboxChange}>
        <input
          type="checkbox"
          id="accountApproval"
          disabled={state.isRegistrationCompleted}
          checked={isCheckboxChecked}
        />
        <Box
          direction="row"
          align="center"
          gap="4px"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center'
          }}
        >
          <FigmaText textKey={Texts.registrationStep4ApprovalLegalIApprove} />
          <Link onClick={(e) => onOpenExternalLink(e, COURSELY_PRIVACY_POLICY)}>
            <FigmaText textKey={Texts.registrationStep4ApprovalLegalPrivacy} />
          </Link>
          <FigmaText textKey={Texts.registrationStep4ApprovalLegalAnd} />
          <Link onClick={(e) => onOpenExternalLink(e, COURSELY_REGISTRATION_RULES)}>
            <FigmaText textKey={Texts.registrationStep4ApprovalLegalCondition} />
          </Link>
        </Box>
      </Link>

      <Box fullWidth align="flex-end">
        {!state.isRegistrationCompleted ? (
          <ContinueButton
            isDisabled={!isCheckboxChecked || !hasMainUser || state?.hasFormErrors}
            onClick={handleContinue}
            disabled={!isCheckboxChecked || !hasMainUser || state?.hasFormErrors}
          >
            <FigmaText textKey={Texts.registrationCtactaContinue} />
            <img src={iconArrow} width={16} height={16} />
            {isLoading ? <Spinner /> : null}
          </ContinueButton>
        ) : null}
      </Box>
    </OpenContainer>
  )
}

const Link = styled(Box)`
  cursor: pointer;
`

export default memo(CourseRegistrationStepThree)
