import { CalendarToday, AccessTime, LocationOn, MoneySharp } from '@mui/icons-material'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import { ActivityMetadataItem } from '../types/activity.types'

export const MOBILE_BREAKPOINT = '600px'
export const CONTAINER_MAX_WIDTH = '850px'

export const getActivityMetadata = (activity: Activity): ActivityMetadataItem[] => [
  { icon: CalendarToday, value: activity.startDate, label: 'Startdatum' },
  { icon: AccessTime, value: activity.time, label: 'Tid för kursen' },
  { icon: LocationOn, value: activity.city ?? '-', label: 'Plats', hideOnMobile: true },
  { icon: MoneySharp, value: `${activity.price / 100} SEK`, label: 'Pris' }
]

export const GROUP_BY_OPTIONS = [
  { value: 'style', label: 'adminGeneralEnumsGroupByGroupByDanceType' },
  { value: 'type', label: 'adminGeneralEnumsGroupByGroupByActivityType' },
  { value: 'city', label: 'adminGeneralEnumsGroupByGroupByCity' },
  { value: 'startdate', label: 'adminGeneralEnumsGroupByGroupByMonth' }
] as const
