import { CloudFunction } from '../../../pure-js/enums/CloudFunction.js'
import {
  EnrollmentRequest,
  EnrollmentRequestResponseV2,
  EnrollmentRequestV2
} from '../../../pure-js/types/GrooverTypes.js'
import {
  CloudFunctionErrorResponse,
  CreatePaymentIntentResponse,
  GetActivePeriodsForUserRequest,
  GetActivePeriodsForUserResponse,
  GetActivitiesForClientRequest,
  ObfuscatedEnrollmentRequest
} from '../../../pure-js/types/QueryParamTypes.js'
import { CreateStripePaymentIntentWebRequest } from '../../../pure-js/types/StripeTypes.js'
import {
  CancelPaymentInput,
  CreatePaymentInput,
  SwishPaymentOKResponse,
  SwishResponseError
} from '../../../pure-js/types/SwishTypes.js'
import { State } from '../hooks/useAppState.js'
import { callFunction } from './CloudFunctionsApiHandlerHelper.js'

export const createStripePaymentIntent = (
  req: CreateStripePaymentIntentWebRequest,
  state: State
): Promise<CloudFunctionErrorResponse | CreatePaymentIntentResponse> =>
  callFunction(CloudFunction.createStripePaymentIntent, state, req)

export const getActivitiesForClient = (req: GetActivitiesForClientRequest, state: State) =>
  callFunction(CloudFunction.getActivitiesForClient, state, req)

export const getObfuscatedEnrollment = (req: ObfuscatedEnrollmentRequest, state: State) =>
  callFunction(CloudFunction.getObfuscatedEnrollment, state, req)

// TODO: this is obsolete; remove
export const saveUserRegistration = (req: EnrollmentRequest, state: State): Promise<string> =>
  callFunction(CloudFunction.saveUserRegistration, state, req) as Promise<string>

export const saveUserRegistrationV2 = (req: EnrollmentRequestV2, state: State): Promise<EnrollmentRequestResponseV2> =>
  callFunction(CloudFunction.saveUserRegistrationV2, state, req) as Promise<EnrollmentRequestResponseV2>

export const createSwishPaymentIntent = (
  req: CreatePaymentInput,
  state: State
): Promise<SwishPaymentOKResponse | SwishResponseError> =>
  callFunction(CloudFunction.createSwishPaymentIntent, state, req)

export const cancelSwishPaymentIntent = (
  req: CancelPaymentInput,
  state: State
): Promise<SwishPaymentOKResponse | SwishResponseError> =>
  callFunction(CloudFunction.cancelSwishPaymentIntent, state, req)

export const getActivePeriodsForUser = (
  req: GetActivePeriodsForUserRequest,
  state: State
): Promise<GetActivePeriodsForUserResponse> =>
  callFunction(CloudFunction.getActivePeriodsForUser, state, req)
