import DayJs from 'dayjs'
import 'dayjs/locale/en.js'
import 'dayjs/locale/sv.js'
import quarterOfYear from 'dayjs/plugin/quarterOfYear.js'
import weekOfYear from 'dayjs/plugin/weekOfYear.js'
import weekday from 'dayjs/plugin/weekday.js'

DayJs.extend(quarterOfYear)
DayJs.extend(weekOfYear)
DayJs.extend(weekday)

export const getWeekdayAbbreviation = (date) => {
  return (
    dayjs(date).locale('sv').format('ddd').charAt(0).toUpperCase() + dayjs(date).locale('sv').format('ddd').slice(1)
  ) // 'ddd' gives the short form of the weekday in Swedish, then capitalize first letter
}

export const dayjs = DayJs
