import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

const SE = 'SE'

export const isValidNumber = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(phone, SE))
  } catch (e) {
    return false
  }
}

export const getInternationalPhoneNumber = (phone: string) => {
  try {
    const parsedNumber = phoneUtil.parse(phone, SE)
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.E164) // return phone in format +46123010203
  } catch (e) {
    return phone
  }
}

export const getPhoneForSwish = (phone: string) => {
  // 1. get phone in international format, ie. +46706112233
  // 2. trim trailing '+' from phone number
  return getInternationalPhoneNumber(phone).replace(/^\+/, '')
}
