enum ZIndices {
  top = 100,
  heaven = 3000,
  modal = 2000,
  focus = 10,
  higher = 2,
  high = 1,
  regular = 0,
  low = -1
}

export default ZIndices
