// using @stripe/stripe-js/pure instead of @stripe/stripe-js allows to lazy load Stripe in the browser
import { loadStripe } from '@stripe/stripe-js/pure'
import { StripeConfig } from '../../../pure-js/types/StripeTypes'

let stripePromise
const getStripe = (config: StripeConfig) => {
  if (!stripePromise) {
    stripePromise = loadStripe(config.secret)
  }
  return stripePromise
}

export default getStripe
