import React from 'react'
import { Button, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'

interface ActivityInfoProps {
  activity: Activity
  client?: Client
}

const RegistrationInfo: React.FC<ActivityInfoProps> = ({ activity, client }) => {
  let logoUrl
  if (client) {
    if (client.logoFullUrl) {
      logoUrl = client.logoFullUrl
    } else if (client.logoUrl) {
      logoUrl = `/assets/logos/${client.logoUrl}`
    }
  }

  return (
    <div className="registration-info">
      <div className="header">
        {client && logoUrl && (<img src={logoUrl} alt={`${client.name}`} className="client-logo" />)}
        <Typography variant="h5" component="h2" className="header-title">
          {activity.name}
        </Typography>

        {/* <div className="overlay-container">
          <IconButton className="share-button" aria-label="share event">
            <ShareIcon />
            <Typography className="share-button-text">Dela eventet</Typography>
          </IconButton>
          <div className="overlay">
            <Typography variant="body2" className="overlay-text">
              Kommer snart
            </Typography>
          </div>
        </div> */}
      </div>

      {/* Description Text */}
      <Typography variant="subtitle1" className="description">
        <div dangerouslySetInnerHTML={{ __html: activity.description }} />
      </Typography>

      {/* Button with Arrow Icon */}
      <div>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          className="action-button"
          href={`/register/${activity.id}`}
        >
          Anmäl mig/oss
        </Button>
      </div>
    </div>
  )
}

export default RegistrationInfo
