import { memo, useEffect, useState } from 'react'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { Controller, UseFormReturn } from 'react-hook-form'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import { CourseRegistrationFormValues, isValidEmail } from '../helpers/CourseRegistrationStepOneHelper'
import styled from '@emotion/styled'
import { ContinueButton } from './StyledComponents'
import { State } from '../hooks/useAppState'
import iconCompleted from '../../public/iconCompleted.svg'
import iconArrow from '../../public/iconArrow.svg'
import { EIGHT_PX, FOUR_PX, TWELVE_PX } from '../enums/Spacings'
import { Checkbox } from '@mui/material'
import { getFigmaText } from '../libs/TextRepository'

type Props = {
  state: State
  formProps: UseFormReturn<CourseRegistrationFormValues, any, undefined>
  partnerRole: 'leader' | 'follower' | 'both'
  changeRole: (isPartnerRole: boolean, newRole: 'leader' | 'follower' | 'both') => void // Updated signature
  handleRemovePartner: () => void
  onSubmit: (data: CourseRegistrationFormValues) => void
  onSetIsPartnerStudent: (isPartnerStudent: boolean) => void
  onSetIsPartnerSenior: (isPartnerSenior: boolean) => void
}

const CourseRegistrationStepOnePartnerForm = ({
  state,
  formProps,
  partnerRole,
  changeRole,
  handleRemovePartner,
  onSubmit,
  onSetIsPartnerStudent,
  onSetIsPartnerSenior
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // Handles resize for mobile/web resolutions
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleSelectStudentDiscount = () => {
    if (state.isRegistrationCompleted) return
    if (state.isPartnerStudent) return onSetIsPartnerStudent(false)
    onSetIsPartnerStudent(true)
    onSetIsPartnerSenior(false)
  }

  const handleSelectSeniorDiscount = () => {
    if (state.isRegistrationCompleted) return
    if (state.isPartnerSenior) return onSetIsPartnerSenior(false)
    onSetIsPartnerStudent(false)
    onSetIsPartnerSenior(true)
  }

  return (
    <FormContainer fullWidth>
      <form>
        <Box fullWidth fullPadding spacing="16px">
          <Box direction="row" gap="15px">
            {state.isRegistrationCompleted && <img src={iconCompleted} width={24} height={24} />}
            <FigmaText
              textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
              text={getFigmaText(Texts.registrationHeadersAndStatusHeaderAttendee)}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="partnerName"
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  disabled={state.isRegistrationCompleted}
                  {...field}
                  value={field.value}
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegNameHelper}
                />
              )}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="partnerPhoneNumber"
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  disabled={state.isRegistrationCompleted}
                  {...field}
                  value={field.value}
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegPhoneNumberHelper}
                />
              )}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="partnerEmail"
              rules={{
                validate: (email) => {
                  if (!email) return false
                  return isValidEmail(email)
                }
              }}
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  disabled={state.isRegistrationCompleted}
                  {...field}
                  value={field.value}
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegEmailHelper}
                />
              )}
            />
          </Box>
          <Box id="user-types-container" fullWidth direction={windowWidth <= 768 ? 'column' : 'row'} align="center">
            <Box id="user-types-roles" direction="row" gap="10px" spacing={EIGHT_PX} alignSelf="flex-start">
              <RoleButton
                isSelected={partnerRole === 'leader'}
                onClick={state.isRegistrationCompleted ? undefined : () => changeRole(true, 'leader')} // Always trigger role change
              >
                <FigmaText textKey={Texts.registrationStep1AttendeesRegRoleLabelLeader} />
              </RoleButton>
              <RoleButton
                isSelected={partnerRole === 'follower'}
                onClick={state.isRegistrationCompleted ? undefined : () => changeRole(true, 'follower')} // Always trigger role change
              >
                <FigmaText textKey={Texts.registrationStep1AttendeesRegRoleLabelFollower} />
              </RoleButton>
              <RoleButton
                isSelected={partnerRole === 'both'}
                onClick={state.isRegistrationCompleted ? undefined : () => changeRole(true, 'both')} // Always trigger role change
              >
                <FigmaText textKey={Texts.registrationStep1AttendeesRegRoleLabelBoth} />
              </RoleButton>
            </Box>
            <Box id="user-types-discounts" direction="row" align="center" alignSelf="flex-start" gap={TWELVE_PX}>
              <Box direction="row" align="center" gap={FOUR_PX}>
                <Checkbox checked={state.isPartnerStudent} onClick={handleSelectStudentDiscount} />
                <FigmaText textKey={Texts.registrationStep3ActivitiesStudentCheckboxLabel} />
              </Box>
              <Box direction="row" align="center" gap={FOUR_PX}>
                <Checkbox checked={state.isPartnerSenior} onClick={handleSelectSeniorDiscount} />
                <FigmaText textKey={Texts.registrationStep3ActivitiesSeniorCheckboxLabel} />
              </Box>
            </Box>
          </Box>
          <Box fullWidth direction="row" align="center" justify="space-between" top spacing="8px">
            <Box direction="row" align="center" gap={TWELVE_PX}>
              {!state.isRegistrationCompleted && (
                <RemovePartnerButton onClick={handleRemovePartner}>
                  <FigmaText textKey={Texts.registrationCtactaAddPartner} text="Ta bort partner" />
                </RemovePartnerButton>
              )}
            </Box>
            {!state.isRegistrationCompleted ? (
              <ContinueButton onClick={formProps.handleSubmit(onSubmit)}>
                <FigmaText textKey={Texts.registrationCtactaContinue} />
                <img src={iconArrow} width={16} height={16} />
              </ContinueButton>
            ) : null}
          </Box>
        </Box>
      </form>
    </FormContainer>
  )
}

const FormContainer = styled(Box)`
  max-width: 865px;
  border-radius: 12px;
  background: #fcfcfc;
  box-shadow: 0px 0px 8px 0px #2422bb;
`

const RoleButton = styled(Box)<{ isSelected: boolean }>`
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 12px;
  background-color: ${({ isSelected }) => (isSelected ? '#0E0349' : 'gray')};
`

const RemovePartnerButton = styled(Box)`
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 12px;
  background-color: #ffdbdb;
`

export default memo(CourseRegistrationStepOnePartnerForm)
