import { RegisterActions } from '../enums/RegisterSteps'

export const registerReducer = (prevState, action) => {
  switch (action.type) {
    case RegisterActions.SET_ACTIVE_STEP:
      return { ...prevState, activeRegisterStep: action.payload?.activeRegisterStep }
    case RegisterActions.SAVE_REGISTRATION_DATA:
      return {
        ...prevState,
        name: action.payload?.name,
        phoneNumber: action.payload?.phoneNumber,
        email: action.payload?.email,
        role: action.payload?.role,
        partnerName: action.payload?.partnerName,
        partnerPhoneNumber: action.payload?.partnerPhoneNumber,
        partnerEmail: action.payload?.partnerEmail,
        partnerRole: action.payload?.partnerRole
      }
    case RegisterActions.SET_IS_REGISTRATION_COMPLETED:
      return { ...prevState, isRegistrationCompleted: true }
    case RegisterActions.SET_FINAL_PRICE:
      return { ...prevState, finalPrice: action.payload?.finalPrice }
    case RegisterActions.SET_FORM_ERRORS:
      return { ...prevState, hasFormErrors: action.payload?.hasFormErrors }
    case RegisterActions.SET_SELECTED_ACTIVITIES:
      return { ...prevState, selectedActivities: action.payload?.selectedActivities }
    case RegisterActions.SET_IS_MAIN_USER_STUDENT:
      return { ...prevState, isMainUserStudent: action.payload?.isMainUserStudent }
    case RegisterActions.SET_IS_PARTNER_STUDENT:
      return { ...prevState, isPartnerStudent: action.payload?.isPartnerStudent }
    case RegisterActions.SET_IS_MAIN_USER_SENIOR:
      return { ...prevState, isMainUserSenior: action.payload?.isMainUserSenior }
    case RegisterActions.SET_IS_PARTNER_SENIOR:
      return { ...prevState, isPartnerSenior: action.payload?.isPartnerSenior }
    case RegisterActions.SET_ENROLLMENT_ID:
      return { ...prevState, enrollmentId: action.payload?.enrollmentId }
    case RegisterActions.SET_PAYMENT_METHOD:
      return { ...prevState, paymentMethod: action.payload?.paymentMethod }
    case RegisterActions.SET_IS_PAID:
      return { ...prevState, isPaid: action.payload?.isPaid }
    default:
      return prevState
  }
}
