import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import styled from '@emotion/styled'
import Box from '../components/Box'
import { Client, Activity } from '../../../pure-js/types/GrooverTypes'

import './ActivityDetailPage.css' // Import the CSS file
import RegistrationInfo from '../components/RegistrationInfo'
import RegistrationPrice from '../components/RegistrationPrice'
import RegistrationOccurences from '../components/RegistrationOccurences'
import RegistrationLocation from '../components/RegistrationLocation'
import { useEffect, useState } from 'react'
import { getActivity, getClient } from '../libs/DBApiHandler'
import FigmaText from '../components/FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'

export const ActivityDetailPage = () => {
  const { activityId } = useParams<{ activityId: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const [activity, setActivity] = useState<Activity | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [client, setClient] = useState<Client | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        setIsLoading(true)
        if (!activityId) {
          setError('Activity ID is missing')
          setIsLoading(false)
          return
        }

        const activityData = await getActivity(activityId)
        if (activityData) {
          setActivity(activityData)

          if (activityData.clientId) {
            const clientData = await getClient(activityData.clientId)
            if (clientData) {
              setClient(clientData)
            } else {
              setError('Client not found')
            }
          }
        } else {
          setError('Activity not found')
        }
      } catch (err) {
        setError('Failed to fetch data')
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchActivity()
  }, [activityId])

  const isActivityValid = activity && activity.name

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <div className="master-container">
      {isLoading ? (
        <div className="spinner">Loading...</div>
      ) : error ? (
        <p className="error-state">{error}</p>
      ) : isActivityValid ? (
        <>
          <Box id="nav-container" direction="row" justify="space-between" align="center">
            {location.state?.from === 'PublicActivitiesPage' && (
              <BackButton onClick={handleBack} direction="row" align="center" gap="8px">
                <ArrowBack sx={{ fontSize: 20 }} />
                <FigmaText textKey={Texts.externalActivityPageNavigationReturnLabel} />
              </BackButton>
            )}
          </Box>
          <Box id="content-container" direction="row" gap="16px" mobileColumn>
            <div className="left-container">
              <RegistrationInfo activity={activity} client={client || undefined} />
              <RegistrationPrice activity={activity} client={client || undefined} />
            </div>
            <div className="right-container">
              {client && <RegistrationLocation activity={activity} customer={client} />}
              <RegistrationOccurences activity={activity} />
            </div>
          </Box>
        </>
      ) : (
        <p className="empty-state">No course or event found</p>
      )}
    </div>
  )
}

const BackButton = styled(Box)`
  cursor: pointer;
  color: #333;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  width: fit-content;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  span {
    font-size: 14px;
  }
`
