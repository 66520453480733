import styled from '@emotion/styled'
import Box from './Box'
import FigmaText from './FigmaText'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { memo } from 'react'

type Props = {
  labelTextKey: TextKey
  valueTextKey: TextKey
  valueText: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const ExpandedActivityDetail = ({ labelTextKey, valueTextKey, valueText, onClick }: Props) => (
  <Box fullWidth direction="row" align="center" justify="space-between">
    <FigmaText textKey={labelTextKey} />
    <ActivityDetailValue isLink={!!onClick} onClick={onClick}>
      <FigmaText textKey={valueTextKey} text={valueText} />
    </ActivityDetailValue>
  </Box>
)

const ActivityDetailValue = styled(Box)<{ isLink?: boolean }>`
  ${({ isLink }) => isLink && ':hover { cursor: pointer; text-decoration: underline; }'}
`

export default memo(ExpandedActivityDetail)
