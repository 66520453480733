import { Discount } from '../../types/DiscountTypes'
import { CalculateDiscountLogic, Calculation, CalculationUserInput } from './CalculateDiscountTypes'

export class ZeroDiscountLogic implements CalculateDiscountLogic {
  type = 'none'
  discountDefinition: Discount

  constructor() {
    this.discountDefinition = {
      id: 'none',
      type: 'none',
      name: 'No discount',
      value: 0,
      calculationType: 'amount',
      appliesTo: 'allParticipants'
    }
  }

  chooseEligible(previousCalculations: Calculation[], userInput: CalculationUserInput) {
    return []
  }

  calculate(previousCalculations: Calculation[], userInput: CalculationUserInput) {
    return previousCalculations
  }
}
