import styled from '@emotion/styled'
import divider from '../../../assets/images/divider.svg'
import Box from './Box'
import { SIXTEEN_PX, TEN_PX } from '../enums/Spacings'
import { BorderRadixes } from '../enums/BorderRadixes'
import RegisterStep from './RegisterStep'
import { memo, useMemo } from 'react'
import { getRegisterSteps } from '../helpers/RegisterStepsHeaderHelper'

type StepsHeaderProps = {
  activeRegisterStep: number
  isRegistrationCompleted: boolean
}

const RegisterStepsHeader = ({ activeRegisterStep, isRegistrationCompleted }: StepsHeaderProps) => {
  const registerSteps = useMemo(() => getRegisterSteps(activeRegisterStep), [activeRegisterStep])

  return (
    <StepsHeader fullWidth fullPadding spacing={SIXTEEN_PX} direction="row" align="center" justify="space-between">
      {registerSteps.map((step, index) => (
        <Box key={`registerStep-${index}`} direction="row" align="center" gap={TEN_PX}>
          <RegisterStep index={index} step={step} isRegistrationCompleted={isRegistrationCompleted} />
          {index < registerSteps.length - 1 && <StepDivider src={divider} />}
        </Box>
      ))}
    </StepsHeader>
  )
}

const StepsHeader = styled(Box)`
  background: #f3f5fa;
  border-radius: ${BorderRadixes.small};
`

const StepDivider = styled.img`
  height: 30px;
  max-width: 50px;

  @media (max-width: 818px) {
    display: none;
  }
`

export default memo(RegisterStepsHeader)
