export const tinySpacing = '0.35rem'
export const defaultSpacing = '1.5rem'
export const bigSpacing = '3rem'
export const reallyBigSpacing = '6rem'

export const TWO_PX = '2px'
export const FOUR_PX = '4px'
export const SIX_PX = '6px'
export const EIGHT_PX = '8px'
export const TEN_PX = '10px'
export const TWELVE_PX = '12px'
export const FOURTEEN_PX = '14px'
export const SIXTEEN_PX = '16px'
export const EIGHTEEN_PX = '18px'
export const TWENTY_PX = '20px'
export const TWENTY_TWO_PX = '22px'
export const TWENTY_FOUR_PX = '24px'
export const TWENTY_SIX_PX = '26px'
export const TWENTY_EIGHT_PX = '28px'
export const THIRTY_PX = '30px'
export const THIRTY_TWO_PX = '32px'
export const THIRTY_FOUR_PX = '34px'
export const THIRTY_SIX_PX = '36px'
export const THIRTY_EIGHT_PX = '38px'
export const FORTY_PX = '40px'
export const FORTY_TWO_PX = '42px'
export const FORTY_FOUR_PX = '44px'
export const FORTY_SIX_PX = '46px'
export const FORTY_EIGHT_PX = '48px'
export const FIFTY_PX = '50px'
export const FIFTY_TWO_PX = '52px'
export const FIFTY_FOUR_PX = '54px'
export const FIFTY_SIX_PX = '56px'
