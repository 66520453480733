import { memo, useEffect, useState } from 'react'
import Box from './Box'
import iconCompleted from '../../public/iconCompleted.svg'
import iconArrow from '../../public/iconArrow.svg'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { Controller, UseFormReturn } from 'react-hook-form'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import { CourseRegistrationFormValues, isValidEmail } from '../helpers/CourseRegistrationStepOneHelper'
import { ContinueButton } from './StyledComponents'
import { State } from '../hooks/useAppState'
import styled from '@emotion/styled'
import { EIGHT_PX, FOUR_PX, TEN_PX, THIRTY_PX, TWELVE_PX } from '../enums/Spacings'
import { Checkbox } from '@mui/material'
import { getFigmaText } from '../libs/TextRepository'
import { REGISTER_PAGE_MAX_WIDTH } from '../enums/HardCodedValues'
import { BorderRadixes } from '../enums/BorderRadixes'

type Props = {
  state: State
  formProps: UseFormReturn<CourseRegistrationFormValues, any, undefined>
  role: 'leader' | 'follower' | 'both'
  changeRole: (isPartnerRole: boolean, newRole: 'leader' | 'follower' | 'both') => void
  hasPartner: boolean
  setHasPartner: (hasPartner: boolean) => void
  onSubmit: (data: CourseRegistrationFormValues) => void
  onSetIsMainUserStudent: (isMainUserStudent: boolean) => void
  onSetIsMainUserSenior: (isMainUserSenior: boolean) => void
}

const CourseRegistrationStepOneNoPartnerForm = ({
  state,
  formProps,
  role,
  changeRole,
  hasPartner,
  setHasPartner,
  onSubmit,
  onSetIsMainUserStudent,
  onSetIsMainUserSenior
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // Handles resize for mobile/web resolutions
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleSelectStudentDiscount = () => {
    if (state.isRegistrationCompleted) return
    if (state.isMainUserStudent) return onSetIsMainUserStudent(false)
    onSetIsMainUserStudent(true)
    onSetIsMainUserSenior(false)
  }

  const handleSelectSeniorDiscount = () => {
    if (state.isRegistrationCompleted) return
    if (state.isMainUserSenior) return onSetIsMainUserSenior(false)
    onSetIsMainUserStudent(false)
    onSetIsMainUserSenior(true)
  }

  return (
    <FormContainer fullWidth>
      <form>
        <Box fullWidth fullPadding spacing="16px">
          <Box direction="row" gap="15px">
            {state.isRegistrationCompleted && <img src={iconCompleted} width={24} height={24} />}
            <FigmaText
              textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
              text={getFigmaText(Texts.registrationHeadersAndStatusHeaderAttendee)}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="name"
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  disabled={state.isRegistrationCompleted}
                  {...field}
                  value={field.value}
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegNameHelper}
                />
              )}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="phoneNumber"
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  disabled={state.isRegistrationCompleted}
                  {...field}
                  value={field.value}
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegPhoneNumberHelper}
                />
              )}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="email"
              rules={{
                validate: (email) => {
                  if (!email) return false
                  return isValidEmail(email)
                }
              }}
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  disabled={state.isRegistrationCompleted}
                  {...field}
                  value={field.value}
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegEmailHelper}
                />
              )}
            />
          </Box>
          <Box
            id="user-types-container"
            fullWidth
            direction={windowWidth <= 768 ? 'column' : 'row'}
            align="flex-start"
            top
            spacing={EIGHT_PX}
          >
            <Box id="user-types-roles" direction="row" gap="10px" spacing={EIGHT_PX} alignSelf="flex-start">
              <RoleButton
                isSelected={role === 'leader'}
                onClick={state.isRegistrationCompleted ? undefined : () => changeRole(false, 'leader')} // Always trigger role change
              >
                <FigmaText textKey={Texts.registrationStep1AttendeesRegRoleLabelLeader} />
              </RoleButton>
              <RoleButton
                isSelected={role === 'follower'}
                onClick={state.isRegistrationCompleted ? undefined : () => changeRole(false, 'follower')} // Always trigger role change
              >
                <FigmaText textKey={Texts.registrationStep1AttendeesRegRoleLabelFollower} />
              </RoleButton>
              <RoleButton
                isSelected={role === 'both'}
                onClick={state.isRegistrationCompleted ? undefined : () => changeRole(false, 'both')} // Always trigger role change
              >
                <FigmaText textKey={Texts.registrationStep1AttendeesRegRoleLabelBoth} />
              </RoleButton>
            </Box>
            <Box id="user-types-discounts" direction="row" align="center" alignSelf="flex-start" gap={TWELVE_PX}>
              <Box direction="row" align="center" gap={FOUR_PX}>
                <Checkbox checked={state.isMainUserStudent} onClick={handleSelectStudentDiscount} />
                <FigmaText textKey={Texts.registrationStep3ActivitiesStudentCheckboxLabel} />
              </Box>
              <Box direction="row" align="center" gap={FOUR_PX}>
                <Checkbox checked={state.isMainUserSenior} onClick={handleSelectSeniorDiscount} />
                <FigmaText textKey={Texts.registrationStep3ActivitiesSeniorCheckboxLabel} />
              </Box>
            </Box>
          </Box>
          <Box
            id="bottom-container"
            fullWidth
            direction={windowWidth <= 768 ? 'column' : 'row'}
            align="center"
            justify="space-between"
            spacing={EIGHT_PX}
            top
          >
            <Box id="cta-button-container" direction="row" justify="space-between" align="center" fullWidth>
              {!state.isRegistrationCompleted && !hasPartner && (
                <AddPartnerButton onClick={() => setHasPartner(true)}>
                  <FigmaText textKey={Texts.registrationCtactaAddPartner} />
                </AddPartnerButton>
              )}
              {!hasPartner && !state.isRegistrationCompleted && (
                <ContinueButton onClick={formProps.handleSubmit(onSubmit)}>
                  <FigmaText textKey={Texts.registrationCtactaContinue} />
                  <img src={iconArrow} width={16} height={16} />
                </ContinueButton>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </FormContainer>
  )
}

const FormContainer = styled(Box)`
  background-color: #fcfcfc;
  max-width: ${REGISTER_PAGE_MAX_WIDTH};
  box-shadow: 0px 0px 8px 0px #2422bb;
  border-radius: ${BorderRadixes.moderate};
`

const RoleButton = styled(Box)<{ isSelected: boolean }>`
  cursor: pointer;
  padding: ${TEN_PX} ${THIRTY_PX};
  border-radius: ${BorderRadixes.moderate};
  background-color: ${({ isSelected }) => (isSelected ? '#0E0349' : 'gray')};
`

const AddPartnerButton = styled(Box)`
  cursor: pointer;
  background-color: #bae5b7;
  padding: ${TEN_PX} ${THIRTY_PX};
  border-radius: ${BorderRadixes.moderate};
`

export default memo(CourseRegistrationStepOneNoPartnerForm)
