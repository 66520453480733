export enum SwishPaymentStatus {
  CREATED = 'CREATED',
  DECLINED = 'DECLINED', // declined by user
  CANCELLED = 'CANCELLED', // cancelled by us
  PAID = 'PAID',
  ERROR = 'ERROR'
}

export type SwishPaymentOKResponse = {
  instructionUUID: string // this is the same as response.headers.location.split('/').pop()
}

export type SwishResponseError = {
  isError: boolean
  source: string
  message: string
}

export type CreatePaymentInput = {
  enrollmentId: string
  payerPhone: string // the phone number of the person paying (format: 46706112233)
}

export type CancelPaymentInput = {
  instructionUUID: string
}

// see https://developer.swish.nu/api/payment-request/v2
export type CreatePaymentRequest = {
  payeePaymentReference: string // our payment ID (enrollment ID)
  callbackUrl: string // webhook endpoing that should be called after payment is confirmed by user
  // payeeAlias = The Swish number of the payee. It needs to match with Merchant Swish number
  // in prod it must match certificate's common name
  payeeAlias: string // Swish phone number, like 12300112233
  payerAlias: string // the phone number of the person paying (format: 46706112233)
  amount: string // must be a string, in SEK, with a dot as decimal separator; 2 decimals max
  currency: string // usually 'SEK'
  message: string // message about the payment/order
  callbackIdentifier?: string // optional, a unique identifier for the callbac
}

export type SwishPaymentIntentStatus = {
  swishEnvironment: SwishConfigEnvironment
  instructionUUID: string // generared by us when creating payment and used by Swish to identify the request
  status: string // 'CREATED' | 'PAID' | 'REFUND' ...
  paymentReference?: string // our enrollment ID; set only when creating payment intent
  payerAlias: string // phone number of the payer
  currency: string
  amount: number
}

export type SwishPaymentIntentStatusUpdate = Partial<SwishPaymentIntentStatus> & {
  instructionUUID: string
}

export type SwishPaymentIntentWebhookResponse = {
  id: string // '89DD769446C37EAD4CEBA07CFB787406' // instruction UUID; generared by us when creating payment and used by Swish to identify the request
  payeePaymentReference: string // 'enrollment-id-0123456789' // our enrollment ID
  paymentReference?: string // '5BA9A7725FF7418A9D6CC99A24EBB7D2' // Swish payment ID, received only in PUT or GET /v1/paymentrequests/{id} response
  callbackUrl: string // 'https://25df-83-10-153-190.ngrok-free.app'
  callbackIdentifier: string // 'D0194DE14DFC448D9A2F701567547600' // not used; we could set it to our ID when creating payment in Swish
  payerAlias: string // '4671234768' // phone number of the payer
  payeeAlias: string // '1231181189' // our Swish number
  currency: string // 'SEK'
  message: string // 'Kingston USB Flash Drive 8 GB'
  errorMessage?: string
  status: string // 'PAID'
  amount: number //
  dateCreated: string // '2024-12-15T18:03:50.727+0100'
  datePaid: string // '2024-12-15T18:03:54.728+0100'
  errorCode?: string
}

export type SwishHttpsAgentProd = {
  cert: string // .pem file from Swish
  key: string // our private key
  passphrase: string // pass for our private key
}

export type SwishHttpsAgentTest = {
  ca: string // usually Swish_TLS_RootCA.pem file
  // .p12 file; its type of Buffer, but we need to store it as Base64 string when saving into Firestore (see upsertSwishConfig)
  // Base64 is decoded back to Buffer when reading data from Firestore (see getSwishConfig)
  pfx: Buffer | string
  passphrase: string // password for .p12 file; for test env it's "swish"
}

export enum SwishConfigEnvironment {
  Test = 'test',
  Production = 'production'
}

type SwishConfigBase = {
  endpoint: string
  payeeAlias: string // phone number who receives money (format: 1234111308)
  callbackUrl: string // webhook callback to be called after create swish payment
}

type SwishConfigProd = SwishConfigBase & {
  environment: SwishConfigEnvironment.Production
  httpsAgent: SwishHttpsAgentProd
}

type SwishConfigTest = SwishConfigBase & {
  environment: SwishConfigEnvironment.Test
  httpsAgent: SwishHttpsAgentTest
}

export type SwishConfig = SwishConfigProd | SwishConfigTest
