import React from 'react'
import { format, parse } from 'date-fns'
import './CourseList.css' // Import the CSS file
import { Activity } from '../../../pure-js/types/GrooverTypes'
import InfoIcon from '@mui/icons-material/Info'
import Button from '@mui/material/Button'

import { sv } from 'date-fns/locale'

interface RegistrationListProps {
  courses: Activity[]
  handleContentChange: () => void
}

const RegistrationList: React.FC<RegistrationListProps> = ({ courses }) => {
  const navigateToCourseRegistrationInNewWindow = (courseId: string) => {
    window.open(`http://${location.host}/register/${courseId}`, '_blank')
  }

  const openRegistrationPage = (courseId: string) => {
    window.open(`http://${location.host}/registration/${courseId}`, '_blank')
  }

  const formatDate = (isoString: string): string => {
    const date = new Date(isoString) // Parse ISO 8601 string
    return format(date, 'd MMM', { locale: sv }).replace(/^\d+\s\w/, (match) => match.toUpperCase()) // Capitalize the first letter of the month
  }

  const formatDayOfWeekAndTime = (dayOfWeek: number, time: string): string => {
    // Create a date string combining dayOfWeek and time
    const dateString = `1970-01-0${dayOfWeek}T${time}:00`

    // Parse the date string
    const date = parse(dateString, "yyyy-MM-dd'T'HH:mm:ss", new Date())

    // Format the date to "EEE HH:mm" and capitalize the first letter
    return format(date, 'EEE HH:mm', { locale: sv }).replace(/^\w/, (c) => c.toUpperCase())
  }

  const translations: { [key: string]: string } = {
    en: 'Sign up',
    sv: 'Anmälan'
  }

  // Get browser language
  const browserLanguage = navigator.language.slice(0, 2) // Get the first two characters (e.g., 'en')

  // Determine button text, default to English if language is not supported
  const buttonText = translations[browserLanguage] || translations['en']

  return (
    <div className="iframe-course">
      <div className="course-table">
        <div className="course-table-header">
          <div className="course-table-row header">
            <div className="course-table-cell expand bold">Namn</div>
            <div className="course-table-cell fixed100">Dag & Tid</div>
            <div className="course-table-cell fixed100">Startdatum</div>
            <div className="course-table-cell fixed75">Pris</div>
            <div className="course-table-cell fixed100">Tillfällen</div>
            <div className="course-table-cell fixed75">Ålder</div>
            <div className="course-table-cell fixed100">Nivå</div>
            <div className="course-table-cell fixed130"></div>
          </div>
        </div>
        <div className="course-table-body">
          {courses.map((course) => (
            <React.Fragment key={course.id}>
              <div className="course-table-row">
                <div className="course-table-cell expand bold">{course.name}</div>
                <div className="course-table-cell fixed100">{formatDate(course.startDate || '')}</div>
                <div className="course-table-cell fixed75">{Math.round(course.price / 100)}</div>
                <div className="course-table-cell fixed100">{course.noOfOccasions}</div>
                <div className="course-table-cell fixed75">{course.ageGroup}</div>
                <div className="course-table-cell fixed100">{course.level}</div>
                <div className="course-table-cell fixed130">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation() // Prevent triggering the row click
                      navigateToCourseRegistrationInNewWindow(course.clientId)
                    }}
                  >
                    {buttonText}
                  </Button>
                  <span
                    style={{ cursor: 'pointer', color: 'darkgray', marginLeft: '10px' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      openRegistrationPage(course.clientId)
                    }}
                    title="Öppna upp kurssidan i en ny flik"
                  >
                    <InfoIcon fontSize="small" />
                  </span>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RegistrationList
