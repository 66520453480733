import { Activity } from '../../../pure-js/types/GrooverTypes'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'

export type GroupByOption = 'style' | 'city' | 'startdate' | 'type'

export const formatDateSwedish = (date: string) => {
  return new Date(date)
    .toLocaleString('sv-SE', { month: 'long', year: 'numeric' })
    .replace(/^\w/, (c) => c.toUpperCase())
}

export const getLocalizedActivityType = (type: string) => {
  const types = {
    Course: getFigmaText(Texts.adminGeneralEnumsActivityTypeCourse),
    Occasion: getFigmaText(Texts.adminGeneralEnumsActivityTypeOccasion)
  }
  return types[type as keyof typeof types] || '-'
}

const sortByDate = (a: string, b: string) => {
  const dateA = new Date(a || '').getTime()
  const dateB = new Date(b || '').getTime()
  return dateA - dateB
}

export const groupActivities = (activities: Activity[], groupBy: GroupByOption) => {
  const grouped = activities.reduce((acc: { [key: string]: Activity[] }, activity) => {
    let groupKey = activity[groupBy] || '-'
    if (groupBy === 'startdate') {
      groupKey = activity.startDate ? formatDateSwedish(activity.startDate) : '-'
    } else if (groupBy === 'type') {
      groupKey = getLocalizedActivityType(activity.type)
    }
    if (!acc[groupKey]) {
      acc[groupKey] = []
    }
    acc[groupKey].push(activity)
    return acc
  }, {})

  // Sort activities within each group by date first, then by name
  Object.values(grouped).forEach((activities) => {
    activities.sort((a, b) => {
      // First sort by date
      const dateCompare = sortByDate(a.startDate, b.startDate)
      if (dateCompare !== 0) return dateCompare

      // If dates are equal, sort by name
      return a.name.localeCompare(b.name)
    })
  })

  // Sort the groups themselves
  const sortedGroups = Object.entries(grouped).sort(([keyA], [keyB]) => {
    if (groupBy === 'startdate') {
      const dateA = grouped[keyA][0]?.startDate || ''
      const dateB = grouped[keyB][0]?.startDate || ''
      return sortByDate(dateA, dateB)
    }
    return keyA.localeCompare(keyB)
  })

  return Object.fromEntries(sortedGroups)
}
