import { useMemo, useEffect } from 'react'
import {
  calculateDiscounts,
  getPriceFromCalculations
} from '../../../pure-js/libs/calculateLogic/CalculateDiscountHelpers'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { State } from '../hooks/useAppState'
import { Calculation } from '../../../pure-js/libs/calculateLogic/CalculateDiscountTypes'

interface UseDiscountCalculationsParams {
  activity?: Activity
  client?: Client
  state: State
}

const useDiscountCalculations = ({ activity, client, state }: UseDiscountCalculationsParams) => {
  const hasMainUser = !!state.name && !!state.email && !!state.phoneNumber

  const hasPartner =
    !!state?.partnerName && !!state?.partnerEmail && !!state?.partnerPhoneNumber && !!state?.partnerRole

  const participantsAmount = hasPartner ? 2 : 1

  const { mainUserPrices, partnerUserPrices, finalPrice } = useMemo(() => {
    let mainUserPrices = [] as Calculation[]
    let partnerUserPrices = [] as Calculation[]
    let finalPrice = 0

    if (activity && client) {
      mainUserPrices = calculateDiscounts(state?.selectedActivities, client, {
        isStudent: state.isMainUserStudent,
        isSenior: state.isMainUserSenior,
        userActivePeriods: state.mainUserActivePeriods,
        participantsAmount
      })

      if (hasPartner) {
        partnerUserPrices = calculateDiscounts(state?.selectedActivities, client, {
          isStudent: state.isPartnerStudent,
          isSenior: state.isPartnerSenior,
          userActivePeriods: state.mainUserActivePeriods,
          participantsAmount
        })
      }

      finalPrice += getPriceFromCalculations(mainUserPrices) / 100
      if (partnerUserPrices) finalPrice += getPriceFromCalculations(partnerUserPrices) / 100
    }

    return { mainUserPrices, partnerUserPrices, finalPrice }
  }, [activity, client, state, hasPartner, participantsAmount])

  const finalPriceMainUser = useMemo(() => getPriceFromCalculations(mainUserPrices) / 100, [mainUserPrices])
  const finalPricePartnerUser = useMemo(() => getPriceFromCalculations(partnerUserPrices) / 100, [partnerUserPrices])

  return {
    hasMainUser,
    hasPartner,
    mainUserPrices,
    partnerUserPrices,
    finalPrice,
    finalPriceMainUser,
    finalPricePartnerUser
  }
}

export default useDiscountCalculations
