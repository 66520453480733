import { Discount } from '../../types/DiscountTypes'
import { Activity, Client } from '../../types/GrooverTypes'
import { CalculateDiscountLogic, Calculation, CalculationUserInput } from './CalculateDiscountTypes'
import { CombinedDiscountLogic } from './CombinedDiscountLogic'
import { CouplesDiscountLogic } from './CouplesDiscountLogic'
import { MemberDiscountLogic } from './MemberDiscountLogic'
import { MultiCourseMatrixDiscountLogic } from './MultiCourseMatrixDiscountLogic'
import { SeniorDiscountLogic } from './SeniorDiscountLogic'
import { StudentDiscountLogic } from './StudentDiscountLogic'
import { ZeroDiscountLogic } from './ZeroDiscountLogic'

// we operate on price in cents, and want to have no decimals
export const fixMathRounding = (n: number): number => Math.round(n / 100) * 100

export const getPriceFromCalculation = (calculation: Calculation) => {
  // if no discounts, return activity price
  if (calculation.discounts.length === 0) return calculation.activity.price
  // else return last discount price
  return calculation.discounts[calculation.discounts.length - 1].output
}

export const getPriceFromCalculations = (calculations: Calculation[]) => {
  return calculations.reduce((acc, calculation) => {
    return fixMathRounding(acc + getPriceFromCalculation(calculation))
  }, 0)
}

export const createInitialCalculations = (activities: Activity[]): Calculation[] => {
  // get initial calculations with no discounts
  return activities.map((activity) => {
    return { activity, discounts: [], finalPrice: activity.price }
  })
}

export const getActivitiesWithDiscountId = (calculations: Calculation[], discountId: string): string[] => {
  return calculations
    .map((calculation) => (calculation.activity.discountIds.includes(discountId) ? calculation.activity.id : null))
    .filter((activityId): activityId is string => activityId !== null)
}

// returns class instance that implements given discount logic
export const discountLogicFactory = (discount: Discount): CalculateDiscountLogic => {
  if (discount.type === 'student') return new StudentDiscountLogic(discount)
  if (discount.type === 'senior') return new SeniorDiscountLogic(discount)
  if (discount.type === 'couples') return new CouplesDiscountLogic(discount)
  if (discount.type === 'combined') return new CombinedDiscountLogic(discount)
  if (discount.type === 'multi_course_matrix') return new MultiCourseMatrixDiscountLogic(discount)
  if (discount.type === 'member') return new MemberDiscountLogic(discount)
  // here? unsupported discount type; will use zero discount logic
  console.warn(`discountLogicFactory: discount type "${discount.type}" is not implemented; will use ZeroDiscountLogic`)
  return new ZeroDiscountLogic()
}

export const calculateDiscounts = (activities: Activity[], client: Client, userInput: CalculationUserInput) => {
  let calculations = createInitialCalculations(activities)
  client.discounts?.reduce((acc, discount) => {
    const logic = discountLogicFactory(discount)
    calculations = logic.calculate(calculations, userInput)
    return calculations
  }, calculations)
  return calculations
}
