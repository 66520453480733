import { DiscountMultiCourseMatrix, Discount } from '../../types/DiscountTypes'
import {
  fixMathRounding,
  getActivitiesWithDiscountId,
  getPriceFromCalculation,
  getPriceFromCalculations
} from './CalculateDiscountHelpers'
import {
  CalculateDiscountLogic,
  Calculation,
  CalculationDiscount,
  CalculationUserInput
} from './CalculateDiscountTypes'

export class MultiCourseMatrixDiscountLogic implements CalculateDiscountLogic {
  type = 'multi_course_matrix'
  discountDefinition: Discount | DiscountMultiCourseMatrix

  constructor(discount: Discount) {
    this.discountDefinition = discount
  }

  chooseEligible(previousCalculations: Calculation[], userInput: CalculationUserInput) {
    return getActivitiesWithDiscountId(previousCalculations, this.discountDefinition.id)
  }

  calculate(previousCalculations: Calculation[], userInput: CalculationUserInput) {
    const eligibleActivities = this.chooseEligible(previousCalculations, userInput)

    // group activities with the same group
    const grouppedActivities: Record<string, Calculation[]> = {}
    previousCalculations.forEach((calculation: Calculation) => {
      if (!eligibleActivities.includes(calculation.activity.id)) return // IMPORTANT exclude activities not set to use this discount
      const group = calculation.activity.group || calculation.activity.id
      grouppedActivities[group] = grouppedActivities[group] || []
      grouppedActivities[group].push(calculation)
    })

    // get price for each group; output: [ { price: 100, actvityIds: [1, 2] }, { price: 200, activityIds: [3] } ]
    const grouppedByPriceAndGroup = Object.entries(grouppedActivities).map(([group, calculations]) => {
      return {
        price: getPriceFromCalculations(calculations as Calculation[]),
        activityIds: calculations.map((c) => c.activity.id)
      }
    })
    // console.log(grouppedByPriceAndGroup)

    // sort activities using strategy
    let sorted = grouppedByPriceAndGroup.map((c) => c.activityIds) // no strategy for sorting yet
    if ((this.discountDefinition as DiscountMultiCourseMatrix).strategy === 'max_price')
      sorted = grouppedByPriceAndGroup.sort((a, b) => (a.price > b.price ? -1 : 1)).map((c) => c.activityIds)
    if ((this.discountDefinition as DiscountMultiCourseMatrix).strategy === 'min_price')
      sorted = grouppedByPriceAndGroup.sort((a, b) => (a.price < b.price ? -1 : 1)).map((c) => c.activityIds)

    // apply discount as many times as many discounts defined in courseDiscounts
    let index = 0
    // console.log('sorted activities:', sorted)
    ;(this.discountDefinition as DiscountMultiCourseMatrix).courseDiscounts.forEach((cd) => {
      if (cd.course <= sorted.length) {
        // console.log(`cd index ${index} applied for ${sorted[index]}`)
        previousCalculations.map((calculation) => {
          if (!sorted[index]?.includes(calculation.activity.id)) return

          const activityPrice = getPriceFromCalculation(calculation)
          const discountAmount =
            this.discountDefinition.calculationType === 'amount'
              ? cd.discount // amount discount
              : fixMathRounding(activityPrice * (cd.discount / 100)) // percentage discount + fix rounding
          const multiCourseMatrixDiscount: CalculationDiscount = {
            type: this.type,
            name: this.discountDefinition.name,
            id: this.discountDefinition.id,
            input: activityPrice,
            discount: discountAmount,
            output: fixMathRounding(activityPrice - discountAmount),
            discountCalculationType: this.discountDefinition.calculationType,
            discountValue: cd.discount
          }
          calculation.discounts = calculation.discounts || []
          calculation.discounts.push(multiCourseMatrixDiscount)
          calculation.finalPrice = multiCourseMatrixDiscount.output
        })
      }
      index++
    })

    return previousCalculations
  }
}
