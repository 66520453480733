const colors = [
  '#E6E6FA', // Light purple
  '#FFD1DC', // Light pink
  '#87CEEB', // Sky blue
  '#DDA0DD', // Plum
  '#F0E68C', // Khaki
  '#FFA07A', // Light salmon
  '#B0E0E6', // Powder blue
  '#D8BFD8', // Thistle
  '#FFB6C1', // Light pink
  '#FFC0CB' // Pink
]

export const getDateColor = (date: string): string => {
  // Use the date string to generate a consistent index
  const hash = date.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0)
  return colors[hash % colors.length]
}
