import styled from '@emotion/styled'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import UserPricing from './PriceContainerUserPricing'
import { Calculation } from '../../../pure-js/libs/calculateLogic/CalculateDiscountTypes'
import { defaultSpacing, TWELVE_PX } from '../enums/Spacings'
import { State } from '../hooks/useAppState'

type Props = {
  mainUserPrices: Calculation[]
  partnerUserPrices?: Calculation[]
  hasPartner: boolean
  finalPrice: number
  state: State
}

const PriceContainerComponent = ({ mainUserPrices, partnerUserPrices, hasPartner, finalPrice, state }: Props) => {
  return (
    <Container fullPadding spacing={defaultSpacing}>
      <UserPricing userPrices={mainUserPrices} userName={state.name as string} />
      {hasPartner && <UserPricing userPrices={partnerUserPrices || []} userName={state.partnerName as string} />}

      {/* Show total discount */}
      {[...mainUserPrices, ...(partnerUserPrices || [])].length > 2 && (
        <TotalDiscountRow
          fullWidth
          direction="row"
          align="center"
          justify="space-between"
          fullPadding
          spacing={TWELVE_PX}
        >
          <FigmaText textKey={Texts.componentsPricingControlSavingsTotal} />
          <FigmaText
            textKey={Texts.componentsPricingControlSavingsTotalValue}
            text={
              [...mainUserPrices, ...(partnerUserPrices || [])]
                .reduce(
                  (sum, calc) =>
                    sum +
                    calc.discounts.reduce((discountSum, discount) => discountSum + (discount.discount || 0), 0) / 100,
                  0
                )
                .toString() + ' SEK'
            }
          />
        </TotalDiscountRow>
      )}

      {/* Show total price */}
      <Box fullWidth direction="row" align="center" justify="space-between" top spacing="22px">
        <FigmaText textKey={Texts.componentsPricingControlPaymentTotalRow} />
        <FigmaText textKey={Texts.componentsPricingControlCssUserSubtotal} text={finalPrice.toString() + ' SEK'} />
      </Box>
    </Container>
  )
}

const TotalDiscountRow = styled(Box)`
  background-color: #f0f7eb;
  corner-radius: 12px;
`

const Container = styled(Box)`
  width: 100%;
  background-color: #efefef;
  border-radius: 12px;
`

export default PriceContainerComponent
