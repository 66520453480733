import { Texts } from '../../../pure-js/libs/Texts'

export const getRegisterSteps = (activeRegisterStep: number) => [
  {
    textKey: Texts.registrationProgressProgressPersonal,
    isActive: activeRegisterStep === 1,
    isCompleted: activeRegisterStep > 1
  },
  {
    textKey: Texts.registrationProgressProgressCourses,
    isActive: activeRegisterStep === 2,
    isCompleted: activeRegisterStep > 2
  },
  {
    textKey: Texts.registrationProgressProgressApproved,
    isActive: activeRegisterStep === 3,
    isCompleted: activeRegisterStep > 3
  },
  {
    textKey: Texts.registrationProgressProgressPayment,
    isActive: activeRegisterStep === 4,
    isCompleted: activeRegisterStep > 4
  },
  {
    textKey: Texts.registrationProgressProgressAllDone,
    isActive: activeRegisterStep === 5,
    isCompleted: activeRegisterStep === 5
  }
]
